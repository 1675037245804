import React, {useContext} from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { privateRoutes, publicRoutes } from '../Routes'
import {LOGIN_ROUTE, CONSOLE_ROUTE } from './constants'
import {useAuthState} from "react-firebase-hooks/auth";
import {Context} from "../Context/Context";


export default function AppRouter() {
    const{auth} = useContext(Context)
    const [user] = useAuthState(auth)



    return user ? (
        <Switch>
            {privateRoutes.map(({path, Component}) =>
                <Route key={path} path={path} component={Component} exact={true}/>
            )}
            <Redirect to={CONSOLE_ROUTE}/>
        </Switch>
    ) : <Switch>
        {publicRoutes.map(({path, Component}) =>
            <Route key={path} path={path} component={Component} exact={true}/>
        )}
        <Redirect to={LOGIN_ROUTE}/>
    </Switch>
}
