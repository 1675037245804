import React, {useContext, useEffect, useState} from 'react';
import s from './console.module.css'
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from '../loader/Loader';
import InStock from "./InStock";
import AddForm from "./AddForm";
import Transactions from "./Transcactions";
import {Context} from "../../Context/Context";
import {useAuthState} from "react-firebase-hooks/auth";
import Button from "@material-ui/core/Button";
import {ADMIN_ROUTE} from "../../utils/constants";
import {Link} from 'react-router-dom';
import {Collapse, List, ListItem, ListItemText} from '@material-ui/core';
import RepairLog from "./RepairLog";
import {useHistory} from "react-router-dom";




const ConsolePage = ({
                         allData, changeRepairStatus, download, upload, file, setFile, sell, setSell,
                         search, changeDevice, changeForm, filterData, digit, addDevice
                     }) => {


    const {auth, value, setValue, edit, setEdit, setFilterData, setNotification} = useContext(Context)
    const [user] = useAuthState(auth)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [open, setOpen] = useState(true)
    const [menu, setMenu] = useState(false)
    const history = useHistory()


    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const deviceAdd = () => {
        if (user.emailVerified === false) {
            return setNotification('Please, confirm your email !')
        }
        setEdit(1)
    }

    useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
    }, []);


    const handleChange = (event, newValue) => {
        setFilterData(null)
        setValue(newValue);

    };
    if (!allData) return <Loader/>

    if (allData.role === 'blocked') {
        auth.signOut()
    }


    const tabs = () => {

        if (value === 0) return <InStock changeDevice={changeDevice}
                                         setSell={setSell}
                                         sell={sell}
                                         changeForm={changeForm}
                                         value={value}
                                         data={filterData ? filterData : allData.devices.filter((d) => {
                                             return d.status === 'Bought'
                                         })}
        />

        if (value === 1) return <Transactions value={value}
                                              download={download}
                                              data={filterData ? filterData : allData.log}
        />

        if (value === 2) return <RepairLog changeRepairStatus={changeRepairStatus} data={filterData ? filterData : allData.repairLog}
                                           download={download}/>
    }


    return (

        <Grid style={{marginTop: '64px', display: 'flex'}}>

            <Grid className={s.sideBar}>

                {/* Drop down tabs & tabs inside dd */}
                <List
                    component="nav"
                    className={s.listTabs}
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem button  onClick={() => { setOpen(open) }} >

                        <ListItemText className={s.toggleTabs} primary="DEALER CONSOLE"/>


                        {/*  toggle arrow open */}
                        {open ? <span style={{fontSize: '19px'}} className="material-icons">expand_more</span>
                            : <span style={{fontSize: '19px'}} className="material-icons">navigate_next</span>}

                    </ListItem>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                            <ListItem>
                                <Tabs
                                    value={value}
                                    orientation={windowDimensions.width > 960 ? 'vertical' : 'horizontal'}
                                    onChange={handleChange}
                                    className={s.tabs}
                                    textColor={'primary'}
                                    TabIndicatorProps={windowDimensions.width > 960 ? {
                                        style: {
                                            width: "100%",
                                            height: '50%',
                                            backgroundColor: "#d0d0d0a3",
                                            zIndex: '-1',
                                        }
                                    } : {}}
                                >
                                    <Tab label={<div className={s.tabsIcon}>
                                        <span style={{marginRight: '5px'}}>In Stock</span></div>}/>

                                    <Tab label={<div className={s.tabsIcon}>
                                        <span style={{marginRight: '5px'}}>Transactions</span></div>}/>
                                    <Tab style={{display: 'none'}} label={<div></div>}/>
                                </Tabs>
                            </ListItem>

                        </List>
                    </Collapse>
                    <ListItem
                        onClick={() => {
                            setFilterData(null)
                            setValue(2)
                        }}
                        selected={value === 2 ? true : false}
                        button>

                        <ListItemText
                            className={s.toggleTabs} primary="Repair console"/>
                    </ListItem>
                    {allData.role === 'admin' ?
                        <ListItem
                            onClick={() => {
                                setValue(0)
                                setFilterData(null)
                                history.push('/admin')
                            }}
                            button>

                            <ListItemText
                                className={s.toggleTabs} primary="Admin console"/>
                        </ListItem>
                        : null}
                </List>


                {/*  Mobile tabs */}


                <div className={s.mobileListTabs}>
                    <span onClick={() => setMenu(!menu)} className="material-icons">menu</span>
                    {menu ?
                        <ul className={s.menu}>
                            <li onClick={() => {
                                setFilterData(null)
                                setValue(0)
                                setMenu(!menu)

                            }}>In stock
                            </li>

                            <li onClick={() => {
                                setFilterData(null)
                                setValue(1)
                                setMenu(!menu)

                            }}>Transactions
                            </li>

                            <li onClick={() => {
                                setFilterData(null)
                                setValue(2)
                                setMenu(!menu)

                            }}>Repair
                            </li>
                        </ul>
                        : null}
                </div>


                {allData.role === 'admin' ?
                    <Button component={Link} onClick={() => {
                        setValue(0)
                        setFilterData(null)
                    }} className={s.adminTab} to={ADMIN_ROUTE}>
                        Admin console
                    </Button>
                    : null}

            </Grid>
            <Box className={s.data}>

                <div className={s.toolbar}>

                    <Box className={s.searchBox}>
                        <input className={s.searchInput} type="text" onChange={(e) => search(e)} placeholder="Search"/>
                        <button className={s.searchButton}>
                            <i className="material-icons">
                                search
                            </i>
                        </button>
                    </Box>

                    <Box className={s.addItem}>
                        <span style={{color: 'black'}} onClick={() => {
                            if (allData.role === 'blocked') return setNotification("Blocked")
                            deviceAdd()
                        }} className={`material-icons ${s.addDevice}`}>
                            add_circle</span>
                    </Box>
                </div>

                {tabs()}

                <AddForm edit={edit}
                         size={windowDimensions}
                         upload={upload}
                         file={file}
                         setFile={setFile}
                         digit={digit}
                         setEdit={setEdit}
                         changeForm={changeForm}
                         addDevice={addDevice}/>
            </Box>
        </Grid>


    )
}


export default ConsolePage;