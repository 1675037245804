import React, { useState } from 'react';
import Box from "@material-ui/core/Box";
import MaterialTable from 'material-table'
import dealer from '../ConsolePage/console.module.css'
import s from '../ConsolePage/console.module.css'
import dwnld from '../../assets/cloud-computing.png'
import ShowDetails from "../ConsolePage/ShowDetails";
import InStock from "../ConsolePage/InStock";
import Loader from '../loader/Loader';



const AdminTransactions = ({ data, value, download, ...props }) => {
    const [device, setDevice] = useState(null)
    const [show, setShow] = useState(0)


    const columns = [
        { field: 'user', title: 'User', cellStyle: { padding: '5px 0 5px 10px', fontSize: '15px', cursor: 'pointer' }, },
        { field: 'IMEI/SN', title: 'IMEI / Serial number', cellStyle: { padding: '5px 0 5px 10px', fontSize: '15px', cursor: 'pointer' }, },
        { field: 'model', title: 'Model', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' } },
        { field: 'person', title: 'Client Name', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' } },
        { field: 'phoneNumber', title: 'Phone', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' } },
        { field: 'amount', title: 'Amount', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' } },
        {
            field: 'image', title: 'Proof', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' },
            render: (rowData) => (rowData.image && rowData.image.length > 0 ?
                <div
                    className={s.dwnld}
                    onClick={() => download(rowData.image)}
                > <img style={{ height: '20px', marginLeft: '10px', cursor: 'pointer' }} alt={'download'} src={dwnld} /> </div> : null
            ),
        },
        { field: 'date', title: 'Date', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' }, },
        {
            field: 'status', title: 'Type', cellStyle: { padding: '3px 0', fontSize: '15px', cursor: 'pointer' },
            render: (rowData) => (
                <div className={s.itemStatus} style={rowData.status === 'Bought' ?
                    { backgroundColor: 'rgb(107, 183, 245)' } :
                    { backgroundColor: 'rgb(138, 198, 136)' }}>
                    {rowData.status}
                </div>),
        }
    ];


    const rows = data.map((d) => {
        return {
            user: d.user,
            'IMEI/SN': d.IMEI ? d.IMEI : d.serialNumber,
            model: d.model,
            address: d.address,
            person: d.person,
            phoneNumber: d.phoneNumber,
            amount: d.amount,
            image: d.image,
            date: d.date,
            status: d.status,
        }
    })

    if (!data) return <Loader/>

    return (
        <>
            <Box
                container='true'
                className={dealer.log}>
                <MaterialTable
                    remoteData={data.length}
                    onRowClick={((evt, selectedRow) => {
                        if (evt.target.tagName !== 'IMG') {
                            setDevice(data[selectedRow.tableData.id]);
                            setShow(1)
                        }
                    })}
                    components={{
                        Toolbar: props => (
                            <div style={{ display: 'none' }} />
                        ),
                    }}
                    options={{
                        search: false,
                        showTitle: false,
                        sorting: true,
                        pageSize: 15,
                        pageSizeOptions: [15, 20, 50],
                        headerStyle: {
                            padding: '10px',
                        }
                    }}
                    data={[...rows]}
                    columns={columns} />

            </Box>
            <Box className={dealer.mobileLog}>
                <InStock download={download} data={data} value={value} />
            </Box>
            {show ? <ShowDetails device={device}
                download={download}
                show={show}
                setShow={setShow} /> : null}
        </>
    );
};

export default AdminTransactions;