import React, {useState} from "react";
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import NavBar from './components/Layout/NavBar'
import AppRouter from './utils/AppRouter';
import {Context} from "./Context/Context";
import {apiContext} from "./api/firebase";


function App() {

    const [allData, setAllData] = useState(null)
    const [filterData, setFilterData] = useState(null)
    const [value, setValue] = useState(0)
    const [notification, setNotification] = useState(null)
    const [price, setPrice] = useState(null)
    const [edit, setEdit] = useState(0)
    const [adminData, setAdminData] = useState([])
    const [adminLog, setAdminLog] = useState([])
    const {firebase, auth, app, firestore} = apiContext

    return (
        <Context.Provider
            value={{
                allData, filterData, value, adminData, setAdminData,
                adminLog, setAdminLog, price, setPrice,
                setValue, setFilterData, setAllData, firebase,
                auth, app, notification, setNotification, edit, setEdit, firestore
            }}>


            <BrowserRouter>
                    <NavBar/>
                    <AppRouter/>
            </BrowserRouter>


        </Context.Provider>
    );
}

export default App;
