import React, {useContext, useState} from 'react';
import s from './admin.module.css'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Loader from "../loader/Loader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Purchase} from "../../Context/Purchase";
import {Context} from "../../Context/Context";


const UserInfo = ({data, blockToggler, setUser}) => {
    const {setNotification} = useContext(Context)
    const [newType, setNewType] = useState(data.subType)


    const button = () => {
        if (data.role === 'user') return <Button className={s.blockButton}
                                                 onClick={() => blockToggler(data, "blocked")}
                                                 variant="contained" color="secondary">
            <span className="material-icons">block</span>
            <p>Block user</p>
        </Button>

        if (data.role === 'blocked') return <Button className={s.blockButton}
                                                    onClick={() => blockToggler(data, "user")}
                                                    variant="contained" color="primary">
            <span className="material-icons">block</span>
            <p>Unblock user</p>
        </Button>

        if (data.role === 'admin') return <p>Administrator</p>
    }

    const saveType = () => {
            Purchase.changeType(newType, data, setNotification)
    }

    function typeChange(e) {
        setNewType(e.target.value)
    }


    if (!data) return <Loader/>

    return (
        <div className={s.userInfo}>
            <div onClick={() => setUser(null)} className={s.backBtn}>
                <span className="material-icons">keyboard_backspace</span>
                <p> Back to list</p>
            </div>
            <Box className={s.main}>

                {/*     USER       */}

                <div className={s.details}>
                    <h3>User Details</h3>
                    <div className={s.detailsItem}>
                        <span>Email</span>
                        <p>{data.user}</p>
                    </div>
                    <div className={s.detailsItem}>
                        <span>Subscription</span>
                        <FormControl className={s.formtype}>
                            <Select
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                value={newType}
                                onChange={typeChange}
                            >
                                <MenuItem value={'Free'}>Free</MenuItem>
                                <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                <MenuItem value={'Half year'}>Half year</MenuItem>
                                <MenuItem value={'Yearly'}>Yearly</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className={s.detailsItem}>
                        <span>Subscription status</span>
                        <p style={data.subStatus === 'active' ? {
                            fontWeight: 'bold',
                            color: '#4caf50'
                        } : null}>
                            {data.subStatus ?
                                data.subStatus[0].toUpperCase() + data.subStatus.slice(1)
                                : 'No subscription'
                            }</p>

                    </div>
                    {data.subStart ?
                        <div className={s.detailsItem}>
                            <span>Subscription start</span>
                            <p>{data.subStart}</p>
                        </div>
                        : null}
                    {data.unlimDate ?
                        <div className={s.detailsItem}>
                            <span>Next due date</span>
                            <p>{data.unlimDate}</p>
                        </div>
                        : null}
                    <div className={s.detailsItem}>
                        <Button color={'primary'}
                                disabled={data.subType === newType}
                                onClick={() => saveType()}
                                variant={'contained'}>
                            <b style={{color: 'white'}}>Save</b>
                        </Button>

                    </div>
                </div>


                {/*     DEVICE      */}


                <div className={s.devices}>
                    <h3>Devices Details</h3>
                    <div className={s.detailsItem}>
                        <span>All device count</span>
                        <p>{data.devices.length}</p>
                    </div>
                    <div className={s.detailsItem}>
                        <span>In stock</span>
                        <p>{data.devices.filter((dev) => {
                            return dev.status === 'Bought'
                        }).length}</p>
                    </div>
                    <div className={s.detailsItem}>
                        <span>Sold</span>
                        <p>{data.devices.filter((dev) => {
                            return dev.status === 'Sold'
                        }).length}</p>
                    </div>
                </div>


                {/*         ACTIONS         */}

                <div className={s.details}>
                    <h3>Data Management</h3>
                    <div className={s.detailsItem}>
                        {button()}
                    </div>
                </div>

            </Box>
        </div>
    );
};

export default UserInfo;