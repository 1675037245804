import Login from "./components/login/Login";
import {
    ADMIN_ROUTE,
    CONSOLE_ROUTE, CONTACT_ROUTE, DISCLAIMER_ROUTE,
    LOGIN_ROUTE,
    MAIN_ROUTE, POLICY_ROUTE,
    PROFILE_ROUTE,
    PURCHASE_ROUTE, REFUNDS_ROUTE, RESET_ROUTE, SETINGS_ROUTE,
    SIGNUP_ROUTE, TERMS_ROUTE
} from "./utils/constants";
import MainPage from "./components/MainPage/MainPage";
import ConsoleContainer from "./components/ConsolePage/ConsoleContainer";
import PurchasePage from "./components/PurchasePage/PurchasePage";
import SignUp from "./components/login/SignUp";
import MyProfile from './components/Profile/MyProfile'
import HocAdmin from "./components/AdminPage/HocAdmin";
import ResetPass from "./components/login/ResetPass";
import Contact from "./components/ContactPage/Contact";
import Policy from "./components/PolicyPage/Policy";
import Disclaimer from "./components/PolicyPage/Disclaimer";
import Terms from "./components/PolicyPage/Terms";
import Refunds from "./components/PolicyPage/Refunds";
import AdminSettings from "./components/AdminPage/AdminSettings";

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Login
    },
    {
        path: SIGNUP_ROUTE,
        Component: SignUp
    },
    {
        path: MAIN_ROUTE,
        Component: MainPage
    },
    {
        path: RESET_ROUTE,
        Component: ResetPass
    },
    {
        path: CONTACT_ROUTE,
        Component: Contact
    },
    {
        path: POLICY_ROUTE,
        Component: Policy
    },
    {
        path: DISCLAIMER_ROUTE,
        Component: Disclaimer,
    },
    {
        path: TERMS_ROUTE,
        Component: Terms,
    },
    {
        path: REFUNDS_ROUTE,
        Component: Refunds,
    },
]





export const privateRoutes = [
    {
        path: CONSOLE_ROUTE,
        Component: ConsoleContainer
    },
    {
        path: PURCHASE_ROUTE,
        Component: PurchasePage
    },
    {
        path: PROFILE_ROUTE,
        Component: MyProfile
    },
    {
        path: ADMIN_ROUTE,
        Component: HocAdmin
    },
    {
        path: CONTACT_ROUTE,
        Component: Contact
    },
    {
        path: POLICY_ROUTE,
        Component: Policy
    },
    {
        path: DISCLAIMER_ROUTE,
        Component: Disclaimer,
    },
    {
        path: TERMS_ROUTE,
        Component: Terms,
    },
    {
        path: REFUNDS_ROUTE,
        Component: Refunds,
    },
    {
        path: SETINGS_ROUTE,
        Component: AdminSettings,
    },
    
]