import React from 'react';
import s from "./console.module.css";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";


const SellForm = ({sell, d, setSell, setDevice, changeForm, changeDevice, ...props}) => {

    const {register, handleSubmit, formState: { errors }} = useForm({
        mode: "onBlur",
    });

    const sellDevice = () => {
        changeDevice(d, 'Sold')
        setDevice(null)
    }

    return (
        <Grid container={true} className={`${s.popup} + ${sell ? s.open : null}`}>
            <form onSubmit={handleSubmit(sellDevice)} className={s.form}>
                <Grid className={s.header}>
                    <h1 className={s.formTitle}>Sell device</h1>
                    <p onClick={() => setSell(0)} className={s.closePopup}>✕</p>
                </Grid>
                <Box className={s.input}>
                    <TextField
                        {...register("person", {
                            required: "Person is required.",
                            minLength: { value: 3, message: "Minimum length is 3 symbols" },
                            pattern: {
                                value: /^[^\s].+[^\s]$/,
                                message: "Name must consist of 3 characters without spaces"
                            }
                        })}
                        onChange={(e) => changeForm(e.target.name, e.target.value)}
                        name='person'
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Client name"
                        type='text'
                        error={Boolean(errors?.person)}
                        helperText={errors?.person?.message}
                    />
                </Box>
                <Box className={s.input}>
                <MuiPhoneNumber
                            {...register("phoneNumber", {
                                required: "Phone Number is required.",
                                minLength: {value: 9, message: "Minimum length is 9 symbols"},
                            })}
                            defaultCountry={"in"}
                            fullWidth
                            variant="outlined"
                            name='phoneNumber'
                            size="small"
                            label="Phone number"
                            onChange={(e) => changeForm('phoneNumber', e)}
                                error={Boolean(errors?.phoneNumber)}
                                helperText={errors?.phoneNumber?.message}
                            type="tel"/>

                </Box>
                <Box className={s.amount}>
                    <TextField
                        {...register("amount", {
                            required: "Amount is required.",
                        })}
                        onChange={(e) => changeForm(e.target.name, e.target.value)}
                        min={0}
                        name='amount'
                        fullWidth
                        label="Amount"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: { min: 1, max: 999999 },
                            startAdornment: (
                                <InputAdornment position="start">
                                    ₹
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(errors?.amount)}
                        helperText={errors?.amount?.message}
                    />
                </Box>

                <Button type="submit">Sell Device</Button>
            </form>
        </Grid>
    );
};

export default SellForm;