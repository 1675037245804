import React, {useContext, useState} from 'react';
import {Container} from "@material-ui/core";
import s from './profile.module.css'
import consoleStyle from '../ConsolePage/console.module.css'
import {Context} from "../../Context/Context";
import Loader from "../loader/Loader";
import {NavLink} from "react-router-dom";
import {CONSOLE_ROUTE} from "../../utils/constants";
import Button from "@material-ui/core/Button";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {useForm} from "react-hook-form";
import {useAuthState} from "react-firebase-hooks/auth";

const MyProfile = () => {

    const {allData, auth, setNotification} = useContext(Context)
    const [loading, setLoading] = useState(false)
    const [newPass, setNewPass] = useState('')
    const [repeatPass, setRepeatPass] = useState(null)
    const [showPass, setShawPass] = useState(false)

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    async function exportData(allData) {
        setLoading(true)

        if (allData.devices.length === 0) {
            setLoading(false)
            return setNotification('No data!')
        }
            const zip = new JSZip();

        const replacer = (key, value) => {

            if (Array.isArray(value)) return ''
            if (value === null) return ''
            else return value
        } // specify how you want to handle null values here
        const header = Object.keys(allData.devices[0])

        const index = header.indexOf('image');
        if (index > -1) {
            const rename = header.includes('IMEI') ? 'serialNumber' : 'IMEI'
            header.splice(index, 1, rename);
        }

        const csv = [
            header.join(','), // header row first
            ...allData.devices.map(row => header.map(fieldName => JSON.stringify(row[fieldName] === 'image' ? '' : row[fieldName], replacer)).join(','))
        ].join('\r\n')

        try {
            await Promise.all(
                allData.devices.map(async (device) => {

                    return await Promise.all(device?.image?.map(async (img, index) => {
                        const imageBlob = await fetch(`${img}`).then(response => response?.blob());
                        const imageFile = await new File([imageBlob], `Proof_${Math.floor(Math.random() * 1000001)}.png`);
                        await zip.file(`${device?.IMEI ? device?.IMEI : device?.serialNumber}_${index + 1}.png`, imageFile, {base64: true});
                        return img
                    }))
                })
            );

        } catch (e) {

        }

        await new Promise((resolve, reject) => setTimeout(resolve, 2000));
        zip.file("All devices.csv", csv, {type: "uint8array"});
        zip.generateAsync({type: 'blob'}).then(function (content) {
            saveAs(content, 'Uvsoft_devices.zip');
        });
        setLoading(false)
    }

    const [user] = useAuthState(auth)


    if (!allData) {
        return <Loader/>
    }


    const resetPass = () => {

        user.updatePassword(repeatPass).then(() => {
            setNotification('Your password has been reset')
        }).catch((error) => {
            setNotification(error.message)
        });


    }

    return (
        <Container className={s.main}>
            <div className={s.line}>
                <div className={s.linePart}>
                    <div className={s.email}>
                        <p>E-mail :</p>
                        <p>{allData.user}</p>
                    </div>
                    <div className={s.subscription}>
                        <p>Subscription type :</p>
                        <p>{allData.subType}</p>
                    </div>
                    <div className={s.subscription}>
                        <p>Devices total :</p>
                        {allData ? <p>{allData.devices.length}</p> : <p>Undefined</p>}
                    </div>

                </div>

                <form onSubmit={handleSubmit(() => resetPass())} className={s.linePart}>
                    <div className={s.title}>
                        <h3>Reset your password :</h3>
                        <span onClick={() => setShawPass(!showPass)}
                              className="material-icons">{showPass ? "visibility" : "visibility_off"}</span>
                    </div>
                    <Box className={consoleStyle.input}>
                        <TextField
                            {...register("newPass", {
                                minLength: {value: 5, message: "Minimum length is 5 symbols"},
                            })}
                            onChange={(e) => {
                                setNewPass(e.target.value)
                            }}
                            name='newPass'
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="New password"
                            type={showPass ? "text" : "password"}
                            error={Boolean(errors?.newPass)}
                            helperText={errors?.newPass?.message}
                        />
                    </Box>
                    <Box className={consoleStyle.input}>
                        <TextField
                            {...register("repeatPass", {
                                minLength: {value: 5, message: "Minimum length is 5 symbols"},
                            })}
                            onChange={(e) => {
                                setRepeatPass(e.target.value)
                            }}
                            name='repeatPass'
                            type={showPass ? "text" : "password"}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Repeat password"
                            error={Boolean(errors?.repeatPass)}
                            helperText={errors?.repeatPass?.message}
                        />
                    </Box>
                    <Button
                        disabled={newPass !== repeatPass}
                        color={'primary'} variant={'contained'}
                        type={'submit'}>Reset</Button>
                </form>

            </div>
            <hr style={{margin: '30px 0'}}/>


            <Box className={s.exportCSV}>
                <p>Export all devices in ".csv" :</p>
                {!loading ?
                    <div className={s.dwnld} onClick={() => exportData(allData)}><span
                        className="material-icons">file_download</span></div>

                    : <div style={{maxHeight: '40px', maxWidth: '40px'}}>

                        <Loader padding={0} marginTop={0}/>

                    </div>}
            </Box>

            <Box style={{display: 'flex'}}>
                <NavLink style={{marginLeft: 'auto'}} to={CONSOLE_ROUTE}>
                    <Button color={'primary'} variant={'contained'}>
                        <span style={{marginRight: '5px'}} className="material-icons">west</span>
                        Back to console</Button>
                </NavLink>
            </Box>

        </Container>
    );
};

export default MyProfile;