import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../Context/Context";
import {useAuthState} from "react-firebase-hooks/auth";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import "firebase/storage";


const HocConsole = ({component: Component, ...rest}) => {

    const {auth, firebase, firestore, edit, setEdit, setValue, value, allData, filterData, setNotification, setFilterData, setAllData} = useContext(Context)
    const [sell, setSell] = useState(0)
    const [user] = useAuthState(auth)
    const [digit, setDigit] = useState('')
    const [form, setForm] = useState({
        status: 'Bought',
        id: Date.now(),
        image: [],
        date: new Date().toISOString().substring(0, 10),
    })
    const [file, setFile] = useState([])
    const [upload, setUpload] = useState(false)

    const changeForm = async (name, value, forma) => {

        if (name === "IMEI" && value.length === 14 && !(/[a-z]/.test(value))) {
            function luhn_checksum(code) {
                let len = code.length
                let parity = len % 2
                let sum = 0
                for (let i = len - 1; i >= 0; i--) {
                    let d = parseInt(code.charAt(i))
                    if (i % 2 === parity) {
                        d *= 2
                    }
                    if (d > 9) {
                        d -= 9
                    }
                    sum += d
                }
                return sum % 10
            }

            function luhn_caclulate(partcode) {
                let checksum = luhn_checksum(partcode + "0")
                return checksum === 0 ? 0 : 10 - checksum
            }

            const result = luhn_caclulate(value)
            let matchIMEI = null;

            if(forma === 'repair') {
                matchIMEI = allData.repairLog.filter(obj => parseInt(value + result) ? obj.IMEI === parseInt(value + result) : null)
            } else {
                matchIMEI = allData.devices.filter(obj => parseInt(value + result) ? obj.IMEI === parseInt(value + result) && obj.status === 'Bought' : null)
            }

            if (matchIMEI.length > 0) {
                return setNotification('A device with this IMEI number already exists')
            } else {
                setDigit(result)
                setForm({...form, [name]: parseInt(value + result)})
            }


        } else if (name === "image") {
            let newArray = []

            for (let i = 0; i < value.files.length; i++) {
                newArray = [...newArray, value.files[i]];
            }
            setFile(newArray)
        } else if (name === "amount") {

            setForm({...form, [name]: '₹ ' + value.replace(/^0+/, '')})

        } else if (name === "serialNumber") {

            let matchSN = null;

            if(forma === 'repair') {
                matchSN = allData.repairLog.filter(obj => value ? obj.serialNumber === value  : null)
            } else {
                matchSN = allData.devices.filter(obj => value ? obj.serialNumber === value && obj.status === 'Bought' : null)
            }
            if (matchSN.length > 0) {
                return setNotification('A device with this Serial number already exists')
            }
            setForm({...form, [name]: value})

        } else {

            setForm({...form, [name]: value})

        }
    }


    const addDevice = async (info) => {
debugger
        if (allData && file) {

            let matchSN = null;

            if(info === 'repair') {
                matchSN = allData.repairLog.filter(obj => form.serialNumber ? obj.serialNumber === form.serialNumber  : null)
            } else {
                matchSN = allData.devices.filter(obj => form.serialNumber ? obj.serialNumber === form.serialNumber && obj.status === 'Bought' : null)
            }
            let matchIMEI = null;

            if(info === 'repair') {
                matchIMEI = allData.repairLog.filter(obj => form.IMEI ? obj.IMEI === form.IMEI : null)
            } else {
                matchIMEI = allData.devices.filter(obj => form.IMEI ? obj.IMEI === form.IMEI && obj.status === 'Bought' : null)
            }


            if (matchIMEI.length > 0 || matchSN.length > 0) {

                return setNotification('A device with this IMEI or Serial number already exists')

            } else {
                setUpload(true)

                const storageRef = firebase.storage().ref();

                for (let i = 0; i < file.length; i++) {
                    const fileRef = storageRef.child(file[i].name + Math.floor(Math.random() * 100000001));
                    await fileRef.put(file[i]);
                    const ref = await fileRef.getDownloadURL()
                    form.image = [...form.image, ref]
                }
                if (info === 'dealer') {
                    firestore.collection("users").doc(user.uid).set({
                        ...allData, devices: [form, ...allData.devices], log: [form, ...allData.log]
                    })
                } else if (info === 'repair') {
                    form.status = 'In progress'
                    firestore.collection("users").doc(user.uid).set({
                        ...allData, repairLog: [ ...allData.repairLog, form],
                    })
                }
                setFile([])
                setEdit(0)
            }
            setUpload(false)
        }
        setForm({
            status: 'Bought',
            id: Date.now(),
            image: [],
            date: new Date().toISOString().substring(0, 10),
        })
    }


    const changeDevice = (index, value) => {

        const ind = allData.devices.filter(dev => {
            return index;
        }).indexOf(index);

        allData.devices[ind] = {...allData.devices[ind], ...form}
        allData.devices[ind].status = value
        allData.devices[ind].image = null
        allData.devices[ind].address = null
        allData.devices[ind].notes = null


        firestore.collection("users").doc(user.uid).set({
            ...allData, devices: [...allData.devices], log: [allData.devices[ind], ...allData.log,]
        })
        setSell(0)

    }


    const changeRepairStatus = (type, index) => {

        const ind = allData.repairLog.map(function(e) { return e.id; }).indexOf(index);

        allData.repairLog[ind].status = type;

        firestore.collection("users").doc(user.uid).set({
            ...allData, repairLog: [...allData.repairLog]
        })
    }


    const search = (e) => {
        let data = [];

        if (value === 0) data = allData.devices.filter((d) => {return d.status === 'Bought'})
        if (value === 1) data = allData.log;
        if (value === 2) data = allData.repairLog;

        let quickResult = data.filter(obj => Object.values(obj).some(val => val ? val.toString().toLowerCase().includes((e.target.value).toLowerCase()) : null));
        if (e.target.value.length === 0) {
            setFilterData(null)
        }
        setFilterData(quickResult)
    }

    async function download(img) {

        const zip = new JSZip();
        for (let i = 0; i < img.length; i++) {
            const imageBlob = await fetch(`${img[i]}`).then(response => response.blob());
            const imageFile = new File([imageBlob], `Proof${i}.jpg`);
            zip.file(`Proof${i}.png`, imageFile, {base64: true});
        }
        zip.generateAsync({type: 'blob'}).then(function (content) {
            saveAs(content, 'Uvsoft_proof_image.zip');
        });
    }

    useEffect(() => {

        firestore.collection("users").doc(user.uid).get().then((q) => setAllData(q.data())
        )
        return () => setFilterData(null)
    }, [edit, firestore, user.uid, setAllData, setFilterData])


    return <Component
        allData={allData}
        file={file}
        setFile={setFile}
        addDevice={addDevice}
        value={value}
        download={download}
        upload={upload}
        search={search}
        changeDevice={changeDevice}
        changeForm={changeForm}
        filterData={filterData}
        digit={digit}
        setValue={setValue}
        sell={sell}
        setSell={setSell}
        changeRepairStatus={changeRepairStatus}

    />
};

export default HocConsole;