import React from 'react';
import HocConsole from "./HocConsole";
import ConsolePage from "./ConsolePage";

const ConsoleContainer = () => {
    return (
        <HocConsole component={ConsolePage}/>
    );
};

export default ConsoleContainer;