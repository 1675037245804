import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

 const app = firebase.initializeApp( {
     apiKey: "AIzaSyDwXU96kpid1lrBqPdcbDG4mIFNyGrtjtk",
     authDomain: "dealer-console.firebaseapp.com",
     projectId: "dealer-console",
     storageBucket: "dealer-console.appspot.com",
     messagingSenderId: "870955986160",
     appId: "1:870955986160:web:f2410af2427ffb414d2635",
     measurementId: "G-TWDTHZSLWK"
});

 const auth = firebase.auth();
 const firestore = firebase.firestore();


export const apiContext = {firebase, auth, app, firestore };