import React from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import s from './purchaseCard.module.css'
import Button from "@material-ui/core/Button";
import {Purchase} from "../../Context/Purchase";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {NavLink, useHistory,} from 'react-router-dom';
import { CONSOLE_ROUTE } from '../../utils/constants';
import Loader from "../loader/Loader";




const PurchaseCard = ({razorpay, firestore, price, user, allData, setNotification, ...props}) => {

    const history = useHistory();

if(!price) return <Loader/>

    return (
        <Container>
            <Typography className={s.title} variant={'h3'}>
                Choose your plan
            </Typography>
            <Box 
                  className={s.container}
            >

               {user?.emailVerified === true && allData?.count && window.location.pathname === '/purchase' ? null : 
               <Grid className={s.cardItem} item xs={12} sm={4}>
                    <div className={`${s.card} ${s.free}`}>
                        <p className={s.cardTitle}>Free</p>
                        <Typography className={s.price} variant={'h2'}>₹ 0.00</Typography>
                        <Paper className={s.cardText}>
                            Up to 10 devices
                        </Paper>
                        <Button className={s.btn}
                            onClick={() => Purchase.setFreePlan( user, history, allData, setNotification)}>Try it
                            <span className="material-icons">arrow_forward</span>
                        </Button>
                    </div>
                </Grid>}



                <Grid className={s.cardItem} item xs={12} sm={4}>
                    <div className={`${s.card} ${s.monthly}`}>
                        <p className={s.cardTitle}>Monthly</p>
                        <Typography className={s.price} variant={'h2'}>₹ {price.Monthly?.toString()}</Typography>
                        <Paper className={s.cardText}>
                        <span className="material-icons">check</span>
                             Unlimited devices
                        </Paper>
                        <Button className={s.btn} onClick={() => { razorpay('Monthly') }}>
                           Buy now
                            <span className="material-icons">arrow_forward</span>
                        </Button>
                    </div>
                </Grid>

                <Grid className={s.cardItem} item xs={12} sm={4}>
                    <div className={`${s.card} ${s.halfYear}`}>
                        <p className={s.cardTitle}>Half year</p>
                        <Typography className={s.price} variant={'h2'}>₹ {price['Half year']?.toString()}</Typography>
                        <Paper className={s.cardText}>
                        <span className="material-icons">check</span>
                             Unlimited devices
                        </Paper>
                        <Button className={s.btn} onClick={() => { razorpay( 'Half year') }}>
                            Buy now
                            <span className="material-icons">arrow_forward</span>
                        </Button>
                    </div>
                </Grid>


                <Grid className={s.cardItem} item xs={12} sm={4}>
                    <div className={`${s.card} ${s.yearly}`}>
                        <p className={s.cardTitle}>Yearly</p>
                        <Typography className={s.price} variant={'h2'}>₹ {price.Yearly?.toString()}</Typography>
                        <Paper className={s.cardText}>
                            <span className="material-icons">check</span>
                                 Unlimited devices
                        </Paper>
                        <Button className={s.btn} onClick={() => { razorpay('Yearly') }}>
                            Buy now
                            <span className="material-icons">arrow_forward</span>
                        </Button>
                    </div>
                </Grid>
                
               

            </Box>
            {window.location.pathname === '/purchase' ?
                <Button variant={'contained'} color={'primary'} className={s.bckBtn}>
                    <NavLink style={{ color: 'white', display:'flex', alignItems: 'center'}} to={CONSOLE_ROUTE}>
                    <span style={{marginRight: '5px'}} className="material-icons">west</span>
                    Back to console
                    </NavLink>
                </Button> : null
                }

               
        </Container>
    );
};

export default PurchaseCard;