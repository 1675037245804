import React, {useContext, useEffect} from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {NavLink, useHistory} from "react-router-dom";
import {LOGIN_ROUTE, CONSOLE_ROUTE, PURCHASE_ROUTE, SIGNUP_ROUTE, PROFILE_ROUTE} from "../../utils/constants";
import {useAuthState} from "react-firebase-hooks/auth";
import s from './NavBar.module.css'
import {Context} from "../../Context/Context";
import Box from "@material-ui/core/Box";
import logo from '../../assets/Vector.png'
import {Purchase} from "../../Context/Purchase";
import avatarPlaceholder from '../../assets/user_avatar_placeholder.png'


export default function NavBar() {
    const {
        auth, firestore, allData, setFilterData, setAllData,
        adminData, adminLog, setPrice,
        value, notification, setNotification, setEdit
    } = useContext(Context)
    const [user] = useAuthState(auth)
    const history = useHistory();
    const url = window.location.pathname.split('/').pop();



    useEffect(() => {
        if (user) {
            firestore.collection("users").doc(user?.uid).onSnapshot((q) => setAllData(q.data())
            )
        }
    }, [firestore, user, setAllData, url])


    const signOut = () => {
        auth.signOut()
        window.location.reload();
    }

    if (allData?.role === 'blocked') {
        auth.signOut()
        setNotification('Your account has been suspended...')
    }

    const search = (e) => {

        let data = [];
        if (window.location.pathname === '/admin') {
            !value ? data = adminData : data = adminLog;
        } else if (window.location.pathname === '/console' && value === 2) {
            data = allData.repairLog
        } else {
            !value ? data = allData.devices.filter((d) => {
                return d.status === 'Bought'
            }) : data = allData.log;
        }

        let quickResult = data.filter(obj => Object.values(obj).some(val => val ? val.toString().toLowerCase().includes((e.target.value).toLowerCase()) : null));
        if (e.target.value.length === 0) {
            setFilterData(null)
        }
        setFilterData(quickResult)
    }

    const deviceAdd = () => {
        if (user.emailVerified === false) {
            return setNotification('Please, confirm your email !')
        }
        if (window.location.pathname !== '/console') {
            history.push(CONSOLE_ROUTE)
        }
        setEdit(1)
    }


    useEffect(() => {

        setTimeout(() => {
            setNotification(null)
        }, 5000);

    }, [notification, setNotification])


    useEffect(() => {

        if (allData?.unlimDate && allData?.unlimDate < new Date().toISOString().substring(0, 10)) {
            Purchase.checkPremium(user, allData, setNotification)
        }

    }, [user, allData, setNotification])


    useEffect(() => {

    }, [url]);

    useEffect(() => {

        async function fetchPrice() {
            const data = await fetch(
                "https://us-central1-dealer-console.cloudfunctions.net/priceCheck",
                {
                    method: "GET", headers: {
                        'Content-type': 'application/json'
                    }
                }
            ).then((t) => t.json());
            setPrice(data)
        }

        fetchPrice()
    }, [setPrice]);


    return (
        <>
            <AppBar style={{boxShadow: 'none', backgroundColor: '#36299e', overflow: 'hidden',}} position="fixed">
                <Toolbar>
                    <Box
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>

                        <Box className={s.logo}>
                            <img style={{height: '50px', marginRight: '10px'}} src={logo} alt=""/>
                            <p className={s.title}>
                                Uvsoft
                            </p>
                            {user && window.location.pathname === '/' ?
                                <NavLink className={s.link} activeClassName={s.activeLink}
                                         to={CONSOLE_ROUTE}> Console</NavLink>
                                : null}
                        </Box>

                        {user ? <>
                                <Box style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>

                                    <div className={s.btn} onClick={() => {
                                        if (allData.role === 'blocked') return setNotification("Blocked")
                                         deviceAdd()
                                    }}>Add device
                                    </div>
                                    <input className={s.navSearch} placeholder={'Search'} onChange={(e) => search(e)}
                                           type="text"/>

                                    {allData && allData.count === 10 && !allData.pay_id ?
                                        <NavLink className={s.link} activeClassName={s.activeLink}
                                                 to={PURCHASE_ROUTE}> Upgrade to
                                            premium</NavLink> : null}
                                    <NavLink style={{height: '42px'}} to={PROFILE_ROUTE}><img
                                        src={user.photoURL ? user.photoURL : avatarPlaceholder}
                                        className={s.userPhoto} alt="#"/></NavLink>
                                    <div onClick={signOut} className={s.signOut}>
                                        <p>Log out</p>
                                        <span className="material-icons">exit_to_app</span>
                                    </div>
                                </Box></>

                            : <div><NavLink className={s.link} activeClassName={s.activeLink} to={LOGIN_ROUTE}>Sign
                                In </NavLink>
                                <NavLink className={s.link} activeClassName={s.activeLink} to={SIGNUP_ROUTE}>Sign
                                    Up </NavLink>
                            </div>

                        }
                    </Box>
                </Toolbar>
            </AppBar>

            <span className={notification ? s.notify : s.closeNotify}> {notification} </span>

        </>
    )
}
