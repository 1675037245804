import React, {useContext, useState} from 'react';

import s from "./admin.module.css";
import {Context} from "../../Context/Context";
import {Link, useHistory} from "react-router-dom";
import dealer from "../ConsolePage/console.module.css";
import Grid from "@material-ui/core/Grid";
import {CONSOLE_ROUTE} from "../../utils/constants";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Loader from "../loader/Loader";

const AdminSettings = () => {
    const [menu, setMenu] = useState(false)
    const {setFilterData, firestore, price, setNotification, setValue} = useContext(Context)
    const history = useHistory()
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const [monthly, setMonthly] = useState(price?.Monthly)
    const [yearly, setYearly] = useState(price?.Yearly)
    const [halfYear, setHalfYear] = useState(price['Half year'])
    const [buttonToggler, setButtonToggler] = useState(false)

    const setPlan = async () => {
        if (monthly < 100 || yearly < 100 || halfYear < 100
            || monthly.length < 3 || yearly.length < 3 || halfYear.length < 3) {
            setNotification('Invalid price')
        }

        setButtonToggler(true)
        await firestore.collection("payment").doc("price").set({
            Monthly: parseInt(monthly), Yearly: parseInt(yearly), "Half year":parseInt(halfYear)
        })

        setTimeout(() => {
            setButtonToggler(false)
            setNotification('Price successfully updated.')
        }, 1500)
    }

    if (!price) return <Loader/>


    return (
        <>
            <Grid className={dealer.sideBar}>

                <div className={s.mobileListTabs}>
                    <span onClick={() => setMenu(!menu)} className="material-icons">menu</span>
                    {menu ?
                        <ul className={s.menu}>
                            <li onClick={() => {
                                setValue(0)
                                setFilterData(null)
                                setMenu(!menu)
                                history.push('/admin')
                            }}>Users
                            </li>
                            <li onClick={() => {
                                setValue(1)
                                setFilterData(null)
                                setMenu(!menu)
                                history.push('/admin')
                            }}>All devices
                            </li>
                            <li onClick={() => {
                                setFilterData(null)
                                setMenu(!menu)
                            }}>Settings
                            </li>
                        </ul>
                        : null}
                </div>
                <Button component={Link} onClick={() => {
                    setValue(0)
                    setFilterData(null)
                }} className={dealer.adminTab} to={CONSOLE_ROUTE}>
                    Dealer console
                </Button>
            </Grid>

            <div className={s.adminSettings}>
                <div onClick={() => history.push('/admin')} className={s.backBtn}>
                    <span className="material-icons">keyboard_backspace</span>
                    <p> Back to console</p>
                </div>

                <form onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(setPlan())
                }} className={s.form}>
                    <h1>Set price :</h1>
                    <div className={s.input}>
                        <TextField
                            {...register("Monthly", {
                                required: "Monthly plan is required.",
                                minLength: {value: 3, message: "Minimum length is 3 symbols"},
                                pattern: {
                                    value: /^[^\s].+[^\s]$/,
                                    message: "Monthly cannot start with a space"
                                }
                            })}
                            onChange={(e) => setMonthly(Number(e.target.value))}
                            name='Monthly'
                            defaultValue={price.Monthly}
                            fullWidth
                            inputProps={{type: 'number'}}
                            variant="outlined"
                            size="small"
                            label="Monthly"
                            type='text'
                            error={Boolean(errors?.Monthly)}
                            helperText={errors?.Monthly?.message}
                        />
                    </div>

                    <div className={s.input}>
                        <TextField
                            {...register("Halfyear", {
                                required: "Half year plan is required.",
                                minLength: {value: 3, message: "Minimum length is 3 symbols"},
                                pattern: {
                                    value: /^[^\s].+[^\s]$/,
                                    message: "Half year cannot start with a space"
                                }
                            })}
                            onChange={(e) => setHalfYear(Number(e.target.value))}
                            name='Halfyear'
                            fullWidth
                            inputProps={{type: 'number'}}
                            defaultValue={price['Half year']}
                            variant="outlined"
                            className={s.input}
                            size="small"
                            label="Half year"
                            type='text'
                            error={Boolean(errors?.Halfyear)}
                            helperText={errors?.Halfyear?.message}
                        />
                    </div>
                    <div className={s.input}>
                        <TextField
                            {...register("Yearly", {
                                required: "Yearly plan is required.",
                                minLength: {value: 3, message: "Minimum length is 3 symbols"},
                                pattern: {
                                    value: /^[^\s].+[^\s]$/,
                                    message: "Yearly cannot start with a space"
                                }
                            })}
                            onChange={(e) => setYearly(Number(e.target.value))}
                            name='Yearly'
                            fullWidth
                            inputProps={{type: 'number'}}
                            defaultValue={price.Yearly}
                            variant="outlined"
                            className={s.input}
                            size="small"
                            label="Yearly"
                            type='text'
                            error={Boolean(errors?.Yearly)}
                            helperText={errors?.Yearly?.message}
                        />
                    </div>
                    <div className={s.button}>
                        <Button type="submit"
                                disabled={buttonToggler}
                                color={'primary'}
                                variant={'contained'}>Change</Button>
                    </div>

                </form>

            </div>
        </>
    );
};

export default AdminSettings;