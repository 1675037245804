import React from "react";
import Container from "@material-ui/core/Container";
import CardContainer from './CardContainer'


const PurchasePage = () => {


    return (
        <Container style={{ marginTop: "100px" }}>
            <CardContainer/>
        </Container>
);
};

export default PurchasePage;