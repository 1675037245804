import React, { useContext, useState } from 'react';
import s from './admin.module.css'
import dealer from '../ConsolePage/console.module.css'
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import UserInfo from "./UserInfo";
import { Context } from "../../Context/Context";
import InStock from '../ConsolePage/InStock';


const AllUsers = ({ data, blockToggler }) => {
    const { firestore } = useContext(Context)
    const [user, setUser] = useState(null)

    const getUser = (id) => {
        firestore.collection("users").doc(id).onSnapshot((q) => setUser(q.data())
        )
    }

    const columns = [
        { field: 'user', title: 'User', cellStyle: { padding: '3px 0', fontSize: '15px', paddingLeft: '80px' }, },
        {
            field: 'devices',
            title: 'Devices count',
            cellStyle: { padding: '3px 0', fontSize: '15px', textAlign: 'center' }
        },

        {
            field: 'bought',
            title: 'Bought',
            cellStyle: { padding: '3px 0', fontSize: '15px', textAlign: 'center', },
        },
        {
            field: 'sold',
            title: 'Sold',
            cellStyle: { padding: '3px 0', fontSize: '15px', textAlign: 'center', },
        },
        {
            field: 'subType',
            title: 'Subscription type',
            cellStyle: { padding: '3px 0', fontSize: '15px', textAlign: 'center' }
        },
        {
            field: 'action', title: 'Action', cellStyle: { padding: '3px 0', fontSize: '15px', textAlign: 'center' },
            render: (rowData) => (
                <Button
                    className={s.info}
                    onClick={() => getUser(rowData.id)}
                > <span className="material-icons">chevron_right</span> </Button>
            ),
        },
    ];
    const rows = data.map((d) => {
        return {
            id: d._id,
            user: d.user,
            devices: d.devices.length,
            subType: d.subType,
            action: '',
            bought: f(d, 'Bought'),
            sold: f(d, 'Sold'),
        }
    })


    function f(d, status) {
        return d.devices.filter((dev) => {
            return dev.status === status
        }).length
    }


    return (
        <>
            <div
                className={dealer.log}>
                {
                    user === null ?
                        <MaterialTable
                            remoteData={data.length}
                            components={{
                                Toolbar: props => (
                                    <div style={{ display: 'none' }} />
                                ),
                            }}
                            options={{
                                search: false,
                                showTitle: false,
                                sorting: true,
                                pageSize: 8,
                                pageSizeOptions: [8, 15, 20, 50],
                                headerStyle: {
                                    padding: '10px',
                                    textAlign: 'center',
                                }
                            }}
                            data={[...rows]}
                            columns={columns} />
                        :
                        <UserInfo
                            blockToggler={blockToggler}
                            setUser={setUser}
                            data={user} />
                }
            </div>
            <div className={dealer.mobileLog}>
                {
                    user === null ?
                        <InStock download={getUser} data={rows} value={5} />
                        
                        : <UserInfo
                            blockToggler={blockToggler}
                            setUser={setUser}
                            data={user} />}
            </div>
        </>
    );
};

export default AllUsers;