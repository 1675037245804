import React, {useState} from 'react';
import s from "./console.module.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SellForm from "./SellForm";
import dwnld from '../../assets/cloud-computing.png'
import Loader from '../loader/Loader';


const InStock = ({data, sell, download, setSell, changeForm, value, changeDevice, changeStatus, page, ...props}) => {

    const [details, setDetails] = useState(false)
    const [device, setDevice] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)


    const detailToggler = (id) => {

        let i = null;
        if (page === 'repair') {

            i = data.filter((obj) => {
                return obj
            }).map((e) => {
                return e.id;
            }).indexOf(id);
        } else {
            i = data.filter((obj) => {
                return obj.address ? obj.address : null
            }).map((e) => {
                return e.id;
            }).indexOf(id);
        }


        const credential = document.querySelectorAll('#cred')[i]
        const title = document.querySelectorAll('#title')[i]
        const arrow = document.querySelectorAll('#arrow')[i]

        setTimeout(() => {
            if (details) {
                credential.style.display = 'none';
                title.innerHTML = 'Show details';
                arrow.innerHTML = 'arrow_drop_down'
            } else {
                credential.style.display = 'flex';
                title.innerHTML = 'Hide details';
                arrow.innerHTML = 'arrow_drop_up'
            }
        }, 200)
        setDetails(!details)

    }
    const status = (value) => {

        switch (value) {
            case 'Fixed' :
                return <p className={s.item}
                          style={{backgroundColor: 'rgb(138, 198, 136)', color: 'white'}}> {value} </p>
            case 'Sold' :
                return <p className={s.item} style={{
                    backgroundColor: 'rgb(138, 198, 136)',
                    padding: '7px 9px',
                    color: 'white'
                }}> {value} </p>
            case 'Not fixed' :
                return <p className={s.item}
                          style={{backgroundColor: 'rgb(231,86,86)', color: 'white'}}>{value} </p>
            default:
                return <p className={s.item}
                          style={{backgroundColor: 'rgb(107, 183, 245)', color: 'white'}}> {value}</p>
        }
    }
    const allPages = data.length / 12;

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const paginateData = paginate(data, 12, currentPage);

    if (!paginateData) return <Loader/>



    return (
        <Paper className={s.table} variant={'outlined'}>
            <Grid
                container={true}
                item
                direction="row"
                alignItems="center"
                height="100%"
            >
                {paginateData.length > 0 ? paginateData.map((d, index) =>
                    <Grid item lg={3} md={6} sm={12} xs={12} key={index}>
                        <Paper className={s.card}>
                            {d.user ?
                                <div className={s.line}>
                                    <p className={s.item}>User :</p>
                                    <p className={s.item}>{d.user}</p>
                                </div> : null
                            }
                            {d.devices ?
                                <div className={s.line}>
                                    <p className={s.item}>Device count :</p>
                                    <p className={s.item}>{d.devices}</p>
                                </div> : null
                            }
                            {d.devices ?
                                <div className={s.line}>
                                    <p className={s.item}>Bought :</p>
                                    <p className={s.item}>{d.bought}</p>
                                </div> : null
                            }
                            {d.devices ?
                                <div className={s.line}>
                                    <p className={s.item}>Sold :</p>
                                    <p className={s.item}>{d.devices - d.bought}</p>
                                </div> : null
                            }
                            {d.subType ?
                                <div className={s.line}>
                                    <p className={s.item}>Subscription type :</p>
                                    <p className={s.item}>{d.subType}</p>
                                </div> : null
                            }
                            {d.IMEI ?
                                <div className={s.line}>
                                    <p className={s.item}>IMEI :</p>
                                    <p className={s.item}>{d.IMEI}</p>
                                </div> : null}
                            {d.serialNumber ?
                                <div className={s.line}>
                                    <p className={s.item}>Serial number :</p>
                                    <p className={s.item}>{d.serialNumber}</p>
                                </div> : null
                            }
                            {d.serialNumber || d.IMEI ?
                                <div className={s.line}>
                                    <p className={s.item}>Model :</p>
                                    <p className={s.item}>{d.model}</p>
                                </div> : null}
                            {d.person || d.serialNumber || d.IMEI ?
                                <div className={s.line}>
                                    <p className={s.item}>Client Name :</p>
                                    <p className={s.item}>{d.person}</p>
                                </div> : null}

                            {d.phoneNumber || d.serialNumber || d.IMEI ?
                                <div className={s.line}>
                                    <p className={s.item}>Phone number :</p>
                                    <p className={s.item}>{d.phoneNumber}</p>
                                </div>
                                : null}

                            {d.amount || d.serialNumber || d.IMEI ?
                                <div className={s.line}>
                                    <p className={s.item}>Amount :</p>
                                    <p className={s.item}>{d.amount}</p>
                                </div>
                                : null}
                            {value && d.image ?
                                <div className={s.line}>
                                    <p className={s.item}>Proof :</p>
                                    <p onClick={() => download(d.image, 'proofImage.jpg')} style={{height: '25px'}}
                                       className={s.item}>
                                        <img style={{height: '23px', marginLeft: '10px', cursor: 'pointer'}}
                                             alt={'download'} src={dwnld}/>
                                    </p>
                                </div>
                                : null}
                            {d.date ?
                                <div className={s.line}>
                                    <p className={s.item}>Date :</p>
                                    <p className={s.item}>{d.date}</p>
                                </div> : null}
                            {d.fault ?
                                <div className={s.line}>
                                    <p className={s.item}>Fault :</p>
                                    <p className={s.item}>{d.fault}</p>
                                </div> : null}
                            {value ?
                                <div className={s.line}>
                                    {d.status ? <>
                                        <p className={s.item}>Type :</p>
                                        {status(d.status)}</> : null}
                                </div>
                                :
                                <Button className={s.itemInStock} variant={'contained'} color={'primary'}
                                        onClick={() => {
                                            setSell(1)
                                            setDevice(d)
                                        }}
                                >Sell</Button>}


                            {/*    details   */}
                            {value && d.address ?
                                <div className={s.details}>

                                    <div onClick={() => detailToggler(d.id)} className={s.showDetails}>
                                        <p id='title'>Show details</p>
                                        <span id='arrow' className="material-icons">arrow_drop_down</span>
                                    </div>
                                    <div id='cred' className={s.credentials}>
                                        {d.address ?
                                            <div className={s.line}>
                                                <p className={s.item}>Address :</p>
                                                <p className={s.item}>{d.address}</p>
                                            </div> : null}
                                        {d.notes ?
                                            <div className={s.line}>
                                                <p className={s.item}>Notes :</p>
                                                <p style={{width: '70%', textAlign: 'end'}}
                                                   className={s.item}>{d.notes}</p>
                                            </div> : null}
                                    </div>

                                </div> : null
                            }

                            {/*     repair    */}


                            {value === 2 && page === 'repair' ?
                                <div className={s.details}>

                                    <div onClick={() => detailToggler(d.id)} className={s.showDetails}>
                                        <p id='title'>Show details</p>
                                        <span id='arrow' className="material-icons">arrow_drop_down</span>
                                    </div>
                                    <div id='cred' style={{
                                        justifyContent: 'space-evenly',
                                        marginBottom: '20px'
                                    }} className={s.credentials}>
                                            {d.notes ?
                                            <div className={s.line}>
                                                <p className={s.item}>Comments :</p>
                                                <p className={s.item}>{d.notes}</p>
                                            </div> : null}
                                        <div className={s.line}>
                                            <p className={s.item}>Change status :</p>
                                        </div>


                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <Button onClick={() => {
                                                detailToggler(d.id)
                                                changeStatus('Fixed', d.status, index, d.id)
                                            }}>
                                                <p className={s.repairStatus}
                                                   style={{backgroundColor: 'rgb(138, 198, 136)'}}> Fixed </p>
                                            </Button>
                                            <Button onClick={() => {
                                                detailToggler(d.id)
                                                changeStatus('Not fixed', d.status, index, d.id)
                                            }}>
                                                <p className={s.repairStatus}
                                                   style={{backgroundColor: 'rgb(231,86,86)'}}>Not fixed </p>
                                            </Button>
                                            <Button onClick={() => {
                                                detailToggler(d.id)
                                                changeStatus('In progress', d.status, index, d.id)
                                            }}>
                                                <p className={s.repairStatus}
                                                   style={{backgroundColor: 'rgb(107, 183, 245)'}}> In progress</p>
                                            </Button>
                                        </div>
                                    </div>

                                </div> : null
                            }


                            {value === 5 ?
                                <div className={s.details}>

                                    <div onClick={() => download(d.id)} className={s.showDetails}>
                                        <p id='title'>Show details</p>
                                        <span id='arrow' className="material-icons">arrow_drop_down</span>
                                    </div>

                                </div> : null
                            }


                        </Paper>
                        {sell ? <SellForm sell={sell}
                                          d={device}
                                          setDevice={setDevice}
                                          setSell={setSell}
                                          changeForm={changeForm}
                                          changeDevice={changeDevice}/> : null}
                    </Grid>
                ) : <p style={{margin: 'auto'}}>No records to display</p>}
            </Grid>
            {data.length >= 12 ?
                <div className={s.line}>
                    <Button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1 ? true : false}
                    >{'< Prev page'}</Button>
                    <Button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage >= allPages ? true : false}
                    >{'Next Page>'}</Button>
                </div>
                : null}
        </Paper>
    );
};

export default InStock;