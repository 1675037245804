import React, { useCallback, useContext } from 'react'
import s from './login.module.css'
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import firebase from 'firebase/app';
import TextField from "@material-ui/core/TextField";
import { Context } from "../../Context/Context";
import { NavLink } from "react-router-dom";
import logo from '../../assets/global-network.png'
import Container from "@material-ui/core/Container";


export default function Login() {
    const { auth, app, firestore, setNotification } = useContext(Context)



    const handleSignUp = useCallback(async event => {
        const { email, password } = event.target.elements;
        event.preventDefault();
        try {
            await app
                .auth()
                .signInWithEmailAndPassword(email.value.trim(), password.value)
        } catch (error) {
            setNotification(error.message);
        }
    }, [app,setNotification])

    const googleLogin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        auth.signInWithPopup(provider).then((result) => {
            firebase.firestore().collection('users').doc(result.user.uid).get()
                .then(doc => {
                    if (!doc.exists) {
                        firestore.collection('users').doc(result.user.uid).set({
                            user: result.user.email,
                            devices: [],
                            log: [],
                            repairLog: [],
                            count: 10,
                            subType: 'Free',
                            role: 'user',
                            _id: result.user.uid,
                        })
                    }
                })
        })
    }



    return (
        <Container>
            <Grid container style={{ height: window.innerHeight - 64, marginTop: '64px'}}
                alignItems={'center'}
                justify={'center'}
                  direction={'column'}
            >
                <div className={s.logo}>
                    <img src={logo} alt=""/>
                    <p >Uvsoft</p>
                </div>

                <Grid className={s.login}
                    container
                    alignItems={'center'}
                    direction={'column'}>
                    <div className={s.title} >
                        Log <span>in</span> to continue to: <br/>
                        <p>Uvsoft</p>
                    </div>
                    <form className={s.form} onSubmit={handleSignUp}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            size='small'
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            size='small'
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button className={s.btn} type='submit'>Log in</Button>
                    </form>
                    <div>
                        <Button onClick={googleLogin} variant={'outlined'}>
                            <img style={{height: '25px', width: '25px', marginRight: '10px'}}
                            src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png" alt="" />
                            Sign in with Google</Button>
                    </div>
                    <Box style={{ padding: '20px 0' }}>
                        <NavLink style={{marginRight: '10px'}} to={'/signup'} >Dont have account?</NavLink>
                        <NavLink to={'/reset'}>Forgot password</NavLink>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}
