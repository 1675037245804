import React, {useEffect} from 'react';
import s from "./policy.module.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {useHistory, useLocation} from "react-router-dom";

const Disclaimer = () => {

    const history = useHistory()
    const {pathname} = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <Container className={s.container}>
            <h3>Website Disclaimer Policy </h3>
            <p>The information contained in this website is for general information purposes only. The information is
                provided by UVSoft and while we endeavor to keep the information up to date and correct, we make no
                representations or warranties of any kind, express or implied, about the completeness, accuracy,
                reliability, suitability or availability with respect to the website or the information, products,
                services, or related graphics contained on the website for any purpose. Any reliance you place on such
                information is therefore strictly at your own risk.</p>

            <p>In no event will we be liable for any loss or damage including without limitation, indirect or
                consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits
                arise out of, or in connection with, the use of this website.</p>

            <p>Through this website you are able to link to other websites which are not under the control of UVSoft. We
                have no control over the nature, content and availability of those sites. The inclusion of any links
                does not necessarily imply a recommendation or endorse the views expressed within them.</p>

            <p>UVSoft will not be responsible for any loss of data .It is advised to keep records and backup of your data from time to time yourself.</p>

            <p>Every effort is made to keep the website up and running smoothly. However, UVSoft  takes no responsibility for, and will not be liable , the website being temporarily unavailable due to technical issues beyond our control.
            </p>


            <Box id={'contact'} className={s.contact}>
                <p><b>UVSoft</b> is located at –</p>

                <p><b>Address :</b> 283-6a, Central Town, Jalandhar, Punjab. 144001</p>
                <p><b>Phone :</b> <a href="tel:+918699603469">+91 8699 603469</a></p>
                <p><b>E-mail :</b>
                    <a aria-label="Email to us"
                       href="mailto:info@uvsoft.co">info@uvsoft.co</a></p>
                <p><b>Website </b>
                    <a href="www.uvsoft.co"> www.uvsoft.co
                    </a>
                </p>
            </Box>
            <Button onClick={() => history.push('/')} variant={'contained'} color={'primary'}> Back to main</Button>
        </Container>
    );
};

export default Disclaimer;