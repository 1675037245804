import {CONSOLE_ROUTE, LOGIN_ROUTE} from "../utils/constants";
import {apiContext} from "../api/firebase";


const {firestore} = apiContext

export const Purchase = {

    setFreePlan(user, history, allData, setNotification) {

        if (user?.emailVerified === false) {
            return setNotification('Please, confirm your email !')
        }
        if (allData && allData.count === 10) {
            history.push(CONSOLE_ROUTE);
        }
        if (allData === null) {
            history.push(LOGIN_ROUTE);
        } else {
            firestore.collection("users").doc(user.uid).update({
                count: 10
            }).then(() => history.push(CONSOLE_ROUTE))
        }

    },

    async checkPremium(user, allData, setNotification) {


        if (Date.parse(allData?.unlimDate) < new Date()) {

            setNotification('Your premium is over, to continue you can buy a new subscription')

            await firestore.collection("users").doc(user.uid).update({
                ...allData,
                unlimDate: null,
                count: 10,
                order_id: null,
                pay_id: null,
                subType: 'Free',
                subStatus: 'pending',
            })

        }

    },

    async changeType(type, user,setNotification) {

        if (type === 'Free') {

            await firestore.collection("users").doc(user._id).update({
                ...user,
                unlimDate: null,
                subStart: null,
                count: 10,
                order_id: 'Changed by admin',
                pay_id: null,
                subType: 'Free',
                subStatus: 'No subscription',
            })
        } else {
            const today = new Date();
            const tomorrow = new Date();
            const days = () => {
                if (type === 'Monthly') return 30
                if (type === 'Half year') return 182
                if (type === 'Yearly') return 365
            }
            await tomorrow.setDate(today.getDate() + days())

            await firestore.collection("users").doc(user._id).update({
                ...user,
                unlimDate: tomorrow.toISOString().substring(0, 10),
                subStart: new Date().toISOString().substring(0, 10),
                count: Infinity,
                order_id: 'Changed by admin',
                pay_id: 'Changed by admin',
                subType: type,
                subStatus: 'active',
            })
        }
        setNotification(`Subscription type for ${user.user} has been changed`)
    }

}