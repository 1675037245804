import React from 'react';
import s from './mainPage.module.css'
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {NavLink} from "react-router-dom";
import { PURCHASE_ROUTE} from "../../utils/constants";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CardContainer from "../PurchasePage/CardContainer";
import trade from '../../assets/trade.png'
import Footer from "../Layout/Footer";



const MainPage = () => {


    AOS.init({
        easing: 'ease',
        offset : 80,
    });


    return (
        <Box>
            <div className={s.headerImage}>
                <Container data-aos-duration='2000' data-aos="fade-right">
                    <Typography className={s.title} variant="h1">
                        A software that <br/> is an expert in itself.
                    </Typography>
                    <Typography className={s.subtitle} variant="h4">
                        It solves another big issue. It saves you from big losses.
                    </Typography>
                    <Box className={s.button}>
                        <Button style={{borderRadius: '30px', marginBottom: '10px'}} color={'secondary'}
                                variant={'contained'}>
                            <NavLink style={{color: 'white'}} to={PURCHASE_ROUTE}>Try it</NavLink>
                        </Button>
                    </Box>
                </Container>
            </div>
            <Container className={s.about} >
                <Typography data-aos="fade-left" data-aos-delay='800' data-aos-duration='2000' className={s.video} variant="h1">
                    VIDEO PLACEHOLDER
                </Typography>



                {/* ABOUT CARDS*/}




                <h1 className={s.aboutTitle} >
                    What do you get with us
                </h1>
                <Box>
                <Box className={s.text} data-aos-delay='50' data-aos-duration='800'
                     style={{marginTop: '40px'}} data-aos="fade-right" >
                    <p className={s.aboutText}> Now , maintain your records regarding sale and purchase of second hand or imported phones.
                        Not just that ,it gives you an option to keep the ID proofs of your dealers and customers,
                        all in one database.</p>
                    <div style={{marginLeft: '70px'}} className={s.icon}><img className={s.aboutImage}
                             src="https://hsm.utimaco.com/wp-content/uploads/2017/07/Applications_Grey_RGB_Database_Encryption-300x300.png"
                                                                              alt="#"/></div>
                </Box>


                <Box style={{marginLeft: 'auto'}} className={s.text} data-aos-delay='300'
                     data-aos-duration='800' data-aos="fade-left" >
                    <div style={{marginRight: '70px'}} className={s.icon}><img className={s.aboutImage}
                                                                               src="https://image.flaticon.com/icons/png/512/130/130304.png"
                                                                               alt="#"/></div>
                    <p className={s.aboutText}> Leave the old age record keeping books that are never of any help when authorities are
                        standing at your doorstep!
                        Uvsoft gives you an easy access to any entry done even years ago by simply entering an IMEI
                        number.</p>
                </Box>


                <Box className={s.text} data-aos-delay='550' data-aos-duration='800' data-aos="fade-right"
                >
                    <p className={s.aboutText}>Dealers purchase phones and give them ahead for repair and in the process, sometimes,
                        they simply forget about some phones because of having so many phones in stock. Which
                        happens due to improper record keeping.</p>
                    <div style={{marginLeft: '70px'}} className={s.icon}><img className={s.aboutImage}
                                                                              src={trade}
                                                                              alt="#"/></div>
                </Box>
                <Box style={{marginLeft: 'auto'}} className={s.text} data-aos-delay='750'
                     data-aos-duration='800' data-aos="fade-left" >
                    <div style={{marginRight: '70px'}} className={s.icon}><img className={s.aboutImage}
                                                                               src="https://img.icons8.com/ios/452/relax.png" alt="#"/></div>
                    <p className={s.aboutText}>
                        Our software maintains and displays full report of phones in stock and phones sold .
                        Save your business and yourself of all the hassle by simply using Uvsoft
                        Let us , at Uvsoft, deal with your hassle, so you can concentrate on your business.
                    </p>
                </Box>
            </Box>



                {/* PURCHASE CARDS*/}



            </Container>
                <CardContainer />
            <Footer/>
        </Box>
    );
};

export default MainPage;