import React, { useCallback, useContext, useEffect } from 'react';
import { Context } from "../../Context/Context";
import { useHistory } from "react-router-dom";
import AdminConsole from "./AdminConsole";
import Loader from '../loader/Loader';

const HocAdmin = () => {

    const { firestore, allData, adminLog, filterData, setAdminLog, adminData, setAdminData } = useContext(Context)
    const history = useHistory();


    const getAdminData = useCallback(async () => {
        const newData = []
        let users = await firestore.collection("users").get();
        await users.forEach(doc => {
            newData.push(doc.data());
        });

        setAdminData(newData)
        await new Promise((resolve, reject) => setTimeout(resolve, 1000));

        const newLog = []
        newData.filter(row => row.log.map(fieldName => {
            newLog.push(fieldName ? { ...fieldName, user: row.user } : null)
            return fieldName
        }))

        setAdminLog(newLog)

    }, [firestore, setAdminLog, setAdminData])

    const blockToggler = (user, role) => {
        firestore.collection("users").doc(user._id).set({
            ...user, role: role
        })
    }

    useEffect(() => {
        if(allData) getAdminData()
    
        return () => setAdminLog([]); // component unmount

    }, [allData,setAdminLog,getAdminData])

    if(!allData) return <Loader/>
    
    if (allData?.role !== 'admin') {
        history.push('/console')
    }

    
    
    return (
        <AdminConsole
            blockToggler={blockToggler}
            data={filterData ? filterData : adminData}
            log={filterData ? filterData : adminLog}
        />
    );
};

export default HocAdmin;