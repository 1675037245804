import React from 'react';
import s from './NavBar.module.css'
import Container from "@material-ui/core/Container";
import {NavLink} from "react-router-dom";


const Footer = () => {
    return (
        <div className={s.footer}>
            <Container className={s.footerInner}>
                <div className={s.linkList}>
                    <h3>Links</h3>
                    <NavLink to={'/contact'}>Contact</NavLink>
                    <NavLink to={'/console'}>Console</NavLink>
                </div>
                <div className={s.linkList}>
                    <h3>Terms & Policy</h3>
                    <NavLink to={'/disclaimer'}>Disclaimer Policy</NavLink>
                    <NavLink to={'/policy'}>Privacy Policy</NavLink>
                    <NavLink to={'/terms'}>Terms & Conditions</NavLink>
                    <NavLink to={'/refunds'}>Refunds & Cancellations</NavLink>
                </div>
                <div className={s.linkList}>
                    <h3>Contact us</h3>
                    <a href={'https://goo.gl/maps/axuc4VpJrEEDb6359'}> 283-6a, Central Town, Jalandhar, Punjab</a>
                    <a href="tel:+918699603469">+91 8699 603469</a>
                    <a aria-label="Email to us"
                       href="mailto:info@uvsoft.co">info@uvsoft.co</a>
                </div>
            </Container>
        </div>
    );
};

export default Footer;