import React, {useState} from 'react';
import s from "../console.module.css";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import MuiPhoneNumber from "material-ui-phone-number";
import {useForm} from "react-hook-form";

const DealerForm = ({stage, setStage,setEdit,setSelectForm,addDevice, size,setIdentify,
                        file,setFile,changeForm,identify,digit, }) => {

    const { register,  trigger, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const [value, setValue] = useState('')

    const handleChange = (e) => {
        setIdentify(e.target.value)
    }

    return (
        <>
            {stage ?
                <form onSubmit={async (e) => {
                    e.preventDefault()
                    changeForm(identify, value)
                    const result = await trigger(["model", "IMEI", "serialNumber", "amount", "image"]);
                    if (result) {
                        setStage(false)
                        e.target.reset()
                    }
                }} className={s.form}>
                    <Grid className={s.header}>
                        <h1 className={s.formTitle}>Add a new device</h1>
                        <p onClick={() => {

                            setEdit(0)
                            setStage(true)
                            setTimeout(() => {
                                setSelectForm(null)
                            }, 400)
                            setFile([])

                        }} className={s.closePopup}>✕</p>
                    </Grid>
                    <Box className={s.stageOne}>

                        <h6 className={s.step}>
                            <span style={{ backgroundColor: '#089f73', border: '2px solid #349679' }} className="material-icons">done</span>
                            <p >Action</p>
                        </h6>
                        <div style={{ backgroundColor: '#089f73' }} />

                        <h6 className={s.step}>
                            <span style={{ backgroundColor: 'rgb(40, 72, 189)', border: '2px solid rgb(54 68 167)' }} className="material-icons">smartphone</span>
                            <p >Device info</p>
                        </h6>

                        <div />
                        <h6 className={s.step}>
                            <span style={{ color: '#68718c' }} className="material-icons">person_add</span>
                            <p >Client info</p>
                        </h6>

                    </Box>
                    <Box className={s.input}>
                        <TextField
                            {...register("model", {
                                minLength: { value: 3, message: "Minimum length is 3 symbols" },
                                pattern: {
                                    value: /^[^\s].+[^\s]$/,
                                    message: "Model cannot start with a space"
                                }
                            })}
                            onChange={(e) => changeForm('model', e.target.value)}
                            name='model'
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Model"
                            type='text'
                            error={Boolean(errors?.model)}
                            helperText={errors?.model?.message}
                        />
                    </Box>

                    <Box className={s.input}>

                        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="identify"
                                    name="identify"
                                    value={identify} onChange={handleChange}>

                            <FormControlLabel value="IMEI"
                                              control={<Radio style={{ padding: '2px 9px' }} color='primary'
                                                              size='small' />}
                                              label="IMEI" />
                            <FormControlLabel value="serialNumber"
                                              control={<Radio color='primary' size='small' />}
                                              label="Serial number" />

                        </RadioGroup>

                    </Box>

                    {identify === 'IMEI' ?
                        <Box className={s.input}>
                            <TextField
                                {...register("IMEI", {
                                    required: "IMEI is required.",
                                    minLength: { value: 14, message: "Minimum length is 14 symbols" },
                                    pattern: {
                                        value: /^(0|[1-9][0-9]*)$/,
                                        message: "IMEI must be 14 digits long"
                                    }
                                })}
                                className={s.checkDigit}
                                onChange={(e) => {
                                    setValue(e.target.value)
                                    changeForm('IMEI', e.target.value)
                                }}
                                name='IMEI'
                                inputProps={{
                                    maxLength: 14
                                }}
                                size="small"
                                variant="outlined"
                                label='IMEI'
                                error={Boolean(errors?.IMEI)}
                                helperText={errors?.IMEI?.message}
                            />
                            <TextField
                                className={s.digit}
                                size="small"
                                variant="outlined"
                                label="Check digit"
                                type="number"
                                value={digit}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Box> :
                        <Box className={s.input}>
                            <TextField
                                {...register("serialNumber", {
                                    required: "Serial Number is required.",
                                    minLength: { value: 9, message: "Minimum length is 9 symbols" },
                                    maxLength: { value: 14, message: "Maximum length is 14 symbols" },
                                })}
                                onChange={(e) => {
                                    setValue(e.target.value)
                                    changeForm('serialNumber', e.target.value)
                                }}
                                name='serialNumber'
                                fullWidth
                                inputProps={{
                                    maxLength: 14
                                }}
                                variant="outlined"
                                size="small"
                                label="Serial number"
                                type='text'
                                error={Boolean(errors?.serialNumber)}
                                helperText={errors?.serialNumber?.message}
                            />
                        </Box>
                    }
                    <Box className={s.amount}>
                        <TextField
                            {...register("amount", {
                            })}
                            onChange={(e) => changeForm('amount', e.target.value)}
                            min={1}
                            name='amount'
                            fullWidth
                            label="Amount"
                            variant="outlined"
                            size="small"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: { min: 1, max: 999999 },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ₹
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(errors?.amount)}
                            helperText={errors?.amount?.message}
                        />
                    </Box>
                    <Box className={s.input}>
                        <TextField
                            {...register("date", {})}
                            onChange={(e) => changeForm('date', e.target.value)}
                            name='date'
                            fullWidth
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={new Date().toISOString().substring(0, 10)}
                            variant="outlined"
                            label="Purchase date"
                            type='date'
                            error={Boolean(errors?.date)}
                            helperText={errors?.date?.message}
                        />
                    </Box>

                    <Box className={s.input}
                         style={{ display: 'none', justifyContent: 'space-between', alignItems: 'center' }}>
                        <input
                            {...register("image", {
                            })}
                            name='image'
                            id='image'
                            multiple
                            accept="image/*"
                            type="file"
                            onChange={(e) => changeForm('image', e.target)}
                        />

                    </Box>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                        <label className={s.imageLabel} htmlFor='image'> Choose image</label>
                        {file?.length > 0 ?
                            <p style={{ marginLeft: '5px', alignSelf: 'center' }}>{file?.length} files
                                uploaded</p> : null}
                    </div>
                    {errors?.image ?
                        <p style={{ color: 'red', fontSize: "0.75rem" }}>{errors?.image?.message}</p> : null}
                    <Button type="submit" >NEXT</Button>
                </form> :







                /*STAGE 2*/










                <form onSubmit={handleSubmit(() => {
                    addDevice('dealer')
                    setStage(true)
                    setSelectForm(null)
                })} className={s.form}>
                    <Grid className={s.header}>
                        <h1 className={s.formTitle}>Add a new device</h1>
                        <p onClick={() => {

                            setEdit(0)
                            setStage(true)
                            setTimeout(() => {
                                setSelectForm(null)
                            }, 400)
                            setFile([])

                        }} className={s.closePopup}>✕</p>
                    </Grid>
                    <Box className={s.stageTwo}>

                        <h6 className={s.step}>
                            <span style={{ backgroundColor: '#089f73', border: '2px solid #349679' }} className="material-icons">done</span>
                            <p >Action</p>
                        </h6>
                        <div style={{ backgroundColor: '#089f73' }} />

                        <h6 className={s.step}>
                            <span style={{ backgroundColor: '#089f73', border: '2px solid #349679' }} className="material-icons">done</span>
                            <p >Device info</p>
                        </h6>

                        <div />
                        <h6 className={s.step}>
                            <span style={{ backgroundColor: 'rgb(40, 72, 189)', border: '2px solid rgb(54 68 167)' }} className="material-icons">person_add</span>
                            <p >Client info</p>
                        </h6>

                    </Box>
                    {/*<span onClick={() => setStage(true)}*/}
                    {/*      style={{marginBottom: '15px', cursor: 'pointer'}}*/}
                    {/*      className="material-icons">west</span>*/}
                    <Box className={s.input}>
                        <TextField
                            {...register("person", {
                                minLength: { value: 3, message: "Minimum length is 3 symbols" },
                                pattern: {
                                    value: /^[^\s].+[^\s]$/,
                                    message: "Name must consist of 3 characters without spaces"
                                }
                            })}
                            onChange={(e) => changeForm('person', e.target.value)}
                            name='person'
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Client name"
                            type='text'
                            error={Boolean(errors?.person)}
                            helperText={errors?.person?.message}
                        />
                    </Box>
                    <Box className={s.input}>
                        <MuiPhoneNumber
                            {...register("phoneNumber", {
                                minLength: { value: 9, message: "Minimum length is 9 symbols" },
                            })}
                            defaultCountry={"in"}
                            fullWidth
                            variant="outlined"
                            name='phoneNumber'
                            size="small"
                            label="Phone number"
                            onChange={(e) => changeForm('phoneNumber', e)}
                            error={Boolean(errors?.phoneNumber)}
                            helperText={errors?.phoneNumber?.message}
                            type="tel" />

                    </Box>
                    <Box className={s.input}>
                        <TextField
                            {...register("address", {
                                minLength: { value: 1, message: "Minimum length is 1 symbols" },
                                maxLength: { value: 50, message: "Minimum length is 50 symbols" },
                                pattern: {
                                    value: /^[^\s].+[^\s]$/,
                                    message: "Address cannot start with a space"
                                }
                            })}
                            onChange={(e) => changeForm('address', e.target.value)}
                            name='address'
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Address"
                            type='text'
                            error={Boolean(errors?.address)}
                            helperText={errors?.address?.message}
                        />
                    </Box>

                    <Box className={s.input}>
                        <TextField
                            {...register("notes", {
                                minLength: { value: 5, message: "Minimum length is 5 symbols" },
                            })}
                            onChange={(e) => changeForm('notes', e.target.value)}
                            name='notes'
                            fullWidth
                            variant="outlined"
                            size="small"
                            multiline
                            rows={size > 320 ? 2 : 1}
                            label="Notes"
                            type='text'
                            error={Boolean(errors?.notes)}
                            helperText={errors?.notes?.message}
                        />
                    </Box>
                    <Button type="submit">Add Device</Button>
                </form>
            }
        </>
    );
};

export default DealerForm;