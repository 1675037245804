import React from 'react';
import s from "./console.module.css";
import Grid from "@material-ui/core/Grid";
import dwnld from "../../assets/cloud-computing.png";


const AddForm = ({ show, setShow, download, device, ...props }) => {

    const status = (value) => {

        switch (value) {
            case 'fixed' :
                return <p className={s.item} style={{backgroundColor: 'rgb(138, 198, 136)', color: 'white'}}> {value} </p>
            case 'Sold' :
                return <p className={s.item} style={{backgroundColor: 'rgb(138, 198, 136)',padding: '7px 9px', color: 'white'}}> {value} </p>
            case 'not fixed' :
                return <p className={s.item} style={{backgroundColor: 'rgb(231,86,86)', color: 'white'}}>{value} </p>
            default:
                return <p className={s.item} style={{backgroundColor: 'rgb(107, 183, 245)', color: 'white'}}> {value}</p>
        }
    }


    return (
        <Grid container={true} className={`${s.popup} + ${show ? s.open : null}`}>
            <div className={s.form}>
                <Grid className={s.header} >
                    <h1 className={s.formTitle}>Device details</h1>
                    <p onClick={() => {
                        setShow(0)
                    }} className={s.closePopup}>✕</p>
                </Grid>
                {device.user ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>User :</p>
                        <p className={s.item}>{device.user}</p>
                    </div> :
                    null
                }
                {device.IMEI ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>IMEI :</p>
                        <p className={s.item}>{device.IMEI}</p>
                    </div> :
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>Serial number :</p>
                        <p className={s.item}>{device.serialNumber}</p>
                    </div>
                }
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className={s.item}>Model :</p>
                    <p className={s.item}>{device.model}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className={s.item}>Client Name :</p>
                    <p className={s.item}>{device.person}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className={s.item}>Phone number :</p>
                    <p className={s.item}>{device.phoneNumber}</p>
                </div>
                {device.address ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>Address :</p>
                        <p className={s.item}>{device.address}</p>
                    </div> : null}

                {device.amount ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className={s.item}>Amount :</p>
                    <p className={s.item}>{device.amount}</p>
                </div> : null}
                {device.image ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>Proof :</p>
                        <p onClick={() => download(device.image, 'proofImage.jpg')} style={{ height: '25px' }}
                            className={s.item}>
                            {device.image ?
                                <img style={{ height: '23px', marginLeft: '10px', cursor: 'pointer' }} alt={'download'}
                                    src={dwnld} /> : null}
                        </p>
                    </div> : null}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className={s.item}>Date :</p>
                    <p className={s.item}>{device.date}</p>
                </div>

                {device.notes ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>Notes :</p>
                        <p style={{ width: '70%', textAlign: 'end' }} className={s.item}>{device.notes}</p>
                    </div> : null}
                    {device.fault ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={s.item}>Fault :</p>
                        <p style={{ width: '70%', textAlign: 'end' }} className={s.item}>{device.fault}</p>
                    </div> : null}

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <p className={s.item}>Type :</p>
                    {status(device.status)}
                </div>
            </div>
            
        </Grid>
    );
};

export default AddForm;
