import React, {useContext, useState} from 'react'
import s from './login.module.css'
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import firebase from 'firebase/app';
import TextField from "@material-ui/core/TextField";
import {Context} from "../../Context/Context";
import {NavLink} from "react-router-dom";
import logo from '../../assets/global-network.png'
import Container from "@material-ui/core/Container";
import {useHistory} from "react-router-dom";


export default function Login() {
    const {setNotification} = useContext(Context)
    const [email, setEmail] = useState()
    const history = useHistory()


    const resetpass = async () => {
        const validation = await validateEmail(email)
        if(validation) {
            firebase.auth().sendPasswordResetEmail(email)
                .then(() => {
                    history.push('/login')
                    setNotification('Please check your email for set new password')
                })
                .catch((error) => {
                    setNotification(error.message)
                    // ..
                });
        } else {
            setNotification('Incorrect e-mail')
        }
    }

    function validateEmail(e) {
        const re = /\S+@\S+\.\S+/;
        return re.test(e);
    }


    return (
        <Container>
            <Grid container style={{height: window.innerHeight - 64, marginTop: '64px'}}
                  alignItems={'center'}
                  justify={'center'}
                  direction={'column'}
            >
                <div className={s.logo}>
                    <img src={logo} alt=""/>
                    <p>Uvsoft</p>
                </div>

                <Grid className={s.login}
                      container
                      alignItems={'center'}
                      direction={'column'}>
                    <div className={s.title}>
                        <div style={{fontSize: 14,}}>
                            Enter your user account's verified email address and we will send you a password reset link
                        </div>
                    </div>
                    <div className={s.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            size='small'
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                    </div>
                    <Button variant={'contained'}
                            color={'primary'}
                            onClick={resetpass}
                            style={{marginTop: 20}}>Reset password</Button>

                    <Box style={{padding: '20px 0'}}>
                        <NavLink style={{marginRight: '10px'}} to={'/signup'}>Dont have account?</NavLink>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}
