import React, {useEffect} from 'react';
import s from "./policy.module.css";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {useHistory, useLocation} from "react-router-dom";


const Refunds = () => {

    const history = useHistory()
    const {pathname} = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <Container className={s.container}>
            <h3>Refunds & Cancellations </h3>
            <h5>Cancellation Policy</h5>
            <p><b>UVSoft</b> believes in helping its customers as far as possible, and has therefore a liberal cancellation
                policy. Under this policy:</p>

            <ul>
                <li>Cancellations will be considered only if the request is made within 72 hours of placing an order.
                </li>
                <li>No cancellations are entertained for those products that the <b>UVSoft</b> marketing team has obtained on
                    special occasions like Pongal, Diwali, and Valentine’s Day etc. These are limited occasion offers
                    and therefore cancellations are not possible.
                </li>
            </ul>
            <h5>Return & Refund Policy</h5>
            <p>We are service providers hence, there is no option for return or refund.
                For cancellation of subscription, please get in touch with our Customer Service Team who will guide you
                on the process for the same at our Costumer Service number <a href="tel:+918699603469">+91 8699 603469</a> within 3 working days (72 hours)
            </p>
            <Button onClick={() => history.push('/')}
                    style={{marginTop: '100px'}}
                    variant={'contained'}
                    color={'primary'}> Back to main</Button>
        </Container>
    );
};

export default Refunds;