export const LOGIN_ROUTE = '/login'
export const SIGNUP_ROUTE = '/signup'
export const MAIN_ROUTE = '/'
export const CONSOLE_ROUTE = '/console'
export const CONTACT_ROUTE = '/contact'
export const POLICY_ROUTE = '/policy'
export const PURCHASE_ROUTE = '/purchase'
export const PROFILE_ROUTE = '/profile'
export const RESET_ROUTE = '/reset'
export const ADMIN_ROUTE = '/admin'
export const SETINGS_ROUTE = '/settings'
export const DISCLAIMER_ROUTE = '/disclaimer'
export const TERMS_ROUTE = '/terms'
export const REFUNDS_ROUTE = '/refunds'