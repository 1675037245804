import React from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import './loader.css'



const Loader = ({ marginTop = 100, padding = 40, ...props}) => {
    return (
        <Container>
            <Grid container style={{marginTop: marginTop}}
                  alignItems={'center'}
                  justify={'center'}
            >
                <Grid
                      container
                      alignItems={'center'}
                      direction={'column'}>
                    <Box style={{padding: padding}}>
                        <div className="lds-roller">
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Loader;