import React, {useContext, useState} from "react";
import { Context } from "../../Context/Context";
import { useAuthState } from "react-firebase-hooks/auth";
import {Link, useHistory} from "react-router-dom";
import PurchaseCard from "./PurchaseCard";
import Loader from "../loader/Loader";
import {CONSOLE_ROUTE, LOGIN_ROUTE} from "../../utils/constants";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}



const CardContainer = () => {
    const { auth, firestore,price, allData, setNotification } = useContext(Context);
    const [user] = useAuthState(auth);
    const history = useHistory();
    const [loading, setLoading] = useState(false)


    async function displayRazorpay(type) {
        
        if (user?.emailVerified === false ) {
            return setNotification('Please, confirm your email !')
        } else if (!user) {
            return history.push(LOGIN_ROUTE)
        } else if (allData.payId && allData.subType !== 'Free' && allData.subStatus === 'active') {
             setNotification('You already have premium !')
            return history.push(CONSOLE_ROUTE)
        }

        setLoading(true)
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            setNotification("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // change in rel
        // firebase.../createOrder
        const data = await fetch(
            "https://us-central1-dealer-console.cloudfunctions.net/createOrder",
            { method: "POST", headers: {
                    'Content-type': 'application/json'
                }, body: JSON.stringify({type}) }
        ).then((t) => t.json());


        const options = {
            key: "rzp_live_hg8vDVCcc5hE0c",
            currency: data.currency,
            order_id: data.id,
            amount: data.amount * 100,
            name: type,
            description: 'Unlimited devices',
            image: "", // change in rel
            handler: function (res) {

                if (data.id === res.razorpay_order_id) {
                    if (type === 'Monthly') {
                        succssesPurchase(30, res.razorpay_order_id, res.razorpay_payment_id)
                    } else if (type === 'Half year') {
                        succssesPurchase(182, res.razorpay_order_id, res.razorpay_payment_id)
                    } else {
                        succssesPurchase(365, res.razorpay_order_id, res.razorpay_payment_id)
                    }
                } else {
                    setNotification(<p>If there is a problem with your transaction, please
                        <Link style={{color: 'blue'}} to={'/contact'}>Contact us</Link> and provide all information
                        about the transaction</p>)
                }
            },
            // prefill: {
            //     email: "sdfdsjfh2@ndsfdf.com",
            //     phone_number: "9899999999",
            // },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoading(false)
    }

    const succssesPurchase = async (days, order_id, pay_id) => {
        const today = new Date();
        const tomorrow = new Date();
        const sub = () => {
            if (days === 30) return 'Monthly'
            if (days === 182) return 'Half year'
            if (days === 365) return 'Yearly'

        }
        await tomorrow.setDate(today.getDate() + days)

        firestore.collection("users").doc(user.uid).update({
            ...allData,
            unlimDate: tomorrow.toISOString().substring(0, 10),
            subStart: new Date().toISOString().substring(0, 10),
            count: Infinity,
            order_id: order_id,
            pay_id: pay_id,
            subType: sub(),
            subStatus: 'active',
        })
        history.push('/console')
    }


    if(loading) {
        return <Loader/>
    }

    return (
        <PurchaseCard razorpay={displayRazorpay} setNotification={setNotification}
                      price={price}
                      firestore={firestore} user={user} allData={allData} />
);
};

export default CardContainer;