import React, {useContext, useState} from 'react';
import s from "./console.module.css";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Loader from "../loader/Loader";
import DealerForm from "./Forms/DealerForm";
import RepairForm from "./Forms/RepairForm";
import Button from "@material-ui/core/Button";
import {Context} from "../../Context/Context";

const AddForm = ({edit, size, upload, file, setFile, setEdit, digit, changeForm, addDevice, ...props}) => {

    const {allData, setNotification} = useContext(Context)
    const [identify, setIdentify] = useState('IMEI')
    const [stage, setStage] = useState(true)
    const [selectForm, setSelectForm] = useState(null)


    function formCheck(type) {

        if (type === 'dealer') {
            if (allData.count <= allData.devices.length) {
                setNotification('You\'ve reached the limits of the free subscription, please buy a premium to continue !')
            } else {
                setSelectForm(type)
            }
        }
        if (type === 'repair') {
            if (allData.count <= allData.repairLog.length) {
                setNotification('You\'ve reached the limits of the free subscription, please buy a premium to continue !')
            } else {
                setSelectForm(type)
            }
        }
    }


    return (
        <Grid container={true} className={`${s.popup} + ${edit ? s.open : null}`}>
            {upload ? <Box className={s.form}>
                <Loader/>
            </Box> : <>


                {/* STAGE  0  (select dealer/repair form)  */}


                {!selectForm ? <div className={s.form}>
                    <Grid style={{marginBottom: '20px'}} className={s.header}>
                        <h1 className={s.formTitle}>Add device</h1>
                        <p onClick={() => {

                            setEdit(0)
                            setStage(true)
                            setTimeout(() => {
                                setSelectForm(null)
                            }, 400)
                            setFile([])

                        }} className={s.closePopup}>✕</p>
                    </Grid>
                    <Box style={{margin: '0 0 10px 0'}} className={s.stageOne}>

                        <h6 className={s.step}>
                            <span style={{backgroundColor: 'rgb(40, 72, 189)', border: '2px solid rgb(54 68 167)'}}
                                  className="material-icons">format_list_bulleted</span>
                            <p>Action</p>
                        </h6>


                        <div/>
                        <h6 className={s.step}>
                            <span style={{color: '#68718c'}} className="material-icons">smartphone</span>
                            <p>Device info</p>
                        </h6>

                    </Box>
                    <Box style={{margin: '20px 0', display: 'flex', flexDirection: 'column'}}>
                        <Button
                            style={{marginBottom: '20px'}}
                            onClick={() => {
                                formCheck('dealer')
                            }}
                        >
                            <span style={{marginRight: '10px'}} className="material-icons">shopping_cart</span>
                            Dealer device</Button>
                        <Button
                            onClick={() => {
                                formCheck('repair')
                            }}
                        >
                            <span style={{marginRight: '10px'}} className="material-icons">build</span>
                            Repair device</Button>

                    </Box>
                </div> : <>


                    {/* STAGE 1 */}


                    {selectForm === 'dealer' ?
                        <DealerForm stage={stage} setStage={setStage} setEdit={setEdit}
                                    setSelectForm={setSelectForm} addDevice={addDevice} setIdentify={setIdentify}
                                    size={size} file={file} setFile={setFile} changeForm={changeForm}
                                    identify={identify} digit={digit}/> :


                        //      reapair console

                        <RepairForm stage={stage} setStage={setStage} setEdit={setEdit} addDevice={addDevice}
                                    setSelectForm={setSelectForm} size={size} file={file}
                                    setFile={setFile} changeForm={changeForm} setIdentify={setIdentify}
                                    identify={identify} digit={digit}/>
                    }
                </>}</>
            }
        </Grid>
    );
};

export default AddForm;
