import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import dealer from "../ConsolePage/console.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import s from "./admin.module.css";
import {Link} from "react-router-dom";
import {CONSOLE_ROUTE} from "../../utils/constants";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import AdminTransactions from "./AdminTranscactions";
import {Context} from "../../Context/Context";
import Loader from "../loader/Loader";
import AllUsers from "./AllUsers";
import Box from "@material-ui/core/Box";
import {List, ListItem, ListItemText} from "@material-ui/core";
import {useHistory} from "react-router-dom";


const AdminConsole = ({log, blockToggler, data}) => {
    const {value, allData, setFilterData, setValue} = useContext(Context)
    const [menu, setMenu] = useState(false)
    const history = useHistory()

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // windiw resize check
    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFilterData(null)
    };

    async function download(img) {

        const zip = new JSZip();
        for (let i = 0; i < img.length; i++) {
            const imageBlob = await fetch(`${img[i]}`).then(response => response.blob());
            const imageFile = new File([imageBlob], `Proof${i}.jpg`);
            zip.file(`Proof${i}.png`, imageFile, {base64: true});
        }
        zip.generateAsync({type: 'blob'}).then(function (content) {
            saveAs(content, 'Uvsoft_proof_image.zip');
        });
    }

    useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
    }, []);

    const search = (e) => {

        let searchData = [];
        !value ? searchData = data : searchData = log;


        let quickResult = searchData.filter(obj => Object.values(obj).some(val => val ? val.toString().toLowerCase().includes((e.target.value).toLowerCase()) : null));
        if (e.target.value.length === 0) {
            setFilterData(null)
        }
        setFilterData(quickResult)
    }


    return (
        <Grid style={{marginTop: '64px', display: 'flex'}}>

            <Grid className={dealer.sideBar}>
                <Tabs
                    value={value}
                    orientation={windowDimensions.width > 960 ? 'vertical' : 'horizontal'}
                    onChange={handleChange}
                    className={dealer.tabs}
                    textColor={'primary'}
                    TabIndicatorProps={windowDimensions.width > 960 ? {
                        style: {
                            width: "100%",
                            backgroundColor: "#d0d0d0a3",
                            zIndex: '-1',
                        }
                    } : {}}
                >
                    <Tab style={{marginRight: '33px'}} label={<div className={dealer.tabsIcon}>
                        <span style={{marginRight: '5px'}} className="material-icons">person</span>
                        Users </div>}/>

                    <Tab style={{marginRight: '33px'}} label={<div className={dealer.tabsIcon}>
                        <span style={{marginRight: '5px'}} className="material-icons">format_list_bulleted</span>
                        All devices </div>}/>

                    <Tab style={{display: 'none'}} label={<div>none </div>}/>
                    <Tab style={{marginRight: '33px'}}
                         onClick={() => {
                             setValue(0)
                             setFilterData(null)
                             history.push('/settings')
                         }}
                         label={<div className={dealer.tabsIcon}>
                             <span style={{marginRight: '5px'}} className="material-icons">settings</span>
                             Settings
                         </div>}/>

                </Tabs>
                <List
                    component="nav"
                    className={dealer.listTabs}
                    aria-labelledby="nested-list-subheader"
                >
                    {allData.role === 'admin' ?
                        <>
                            <ListItem
                                onClick={() => {
                                    setFilterData(null)
                                    history.push('/console')
                                }}
                                selected={value === 2 ? true : false}
                                button>

                                <ListItemText
                                    className={dealer.toggleTabs} primary="Dealer console"/>
                            </ListItem>
                        </>
                        : null}
                </List>

                {/*  Mobile tabs */}
                <div className={s.mobileListTabs}>
                    <span onClick={() => setMenu(!menu)} className="material-icons">menu</span>
                    {menu ?
                        <ul className={s.menu}>
                            <li onClick={() => {
                                setValue(0)
                                setFilterData(null)
                                setMenu(!menu)
                            }}>Users
                            </li>
                            <li onClick={() => {
                                setValue(1)
                                setFilterData(null)
                                setMenu(!menu)
                            }}>All devices
                            </li>
                            <li onClick={() => {
                                setFilterData(null)
                                setMenu(!menu)
                                history.push('/settings')
                            }}>Settings
                            </li>
                        </ul>
                        : null}

                </div>

                <Button component={Link} onClick={() => {
                    setValue(0)
                    setFilterData(null)
                }} className={dealer.adminTab} to={CONSOLE_ROUTE}>
                    Dealer console
                </Button>
            </Grid>


            {data.length !== 0 ?
                <div className={dealer.data}>
                    <div className={dealer.toolbar}>

                        <Box style={{right: '20px'}} className={dealer.searchBox}>
                            <input className={dealer.searchInput} type="text" onChange={(e) => search(e)}
                                   placeholder="Search"/>
                            <button className={dealer.searchButton}>
                                <i className="material-icons">
                                    search
                                </i>
                            </button>
                        </Box>
                    </div>
                    {!value ?
                        <AllUsers
                            blockToggler={blockToggler}
                            data={data}
                        />
                        : <AdminTransactions
                            value={value}
                            download={download}
                            data={log}/>}
                </div>
                : <Loader/>}
        </Grid>
    );
};

export default React.memo(AdminConsole);