import React from 'react';
import {Container} from "@material-ui/core";
import s from "../PolicyPage/policy.module.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useHistory} from 'react-router-dom'

const Contact = () => {
    const history = useHistory()
    return (
        <Container className={s.container}>
            <Box className={s.contactPage}>
                <h4>Contacting Us</h4>

                <p>Company Name - UVSoft</p>
                <p>Address- 283-6a, Central Town, Jalandhar, Punjab. 144001</p>
                <p >Telephone Number - <a href="tel:+918699603469">+91 8699 603469</a></p>
                <p>E-Mail ID -
                    <a aria-label="Email to us"
                       href="mailto:info@uvsoft.co">info@uvsoft.co</a></p>
            </Box>
            <Button onClick={() => history.push('/')} variant={'contained'} color={'primary'} > Back to main</Button>
        </Container>
    );
};

export default Contact;