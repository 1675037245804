import React, {useCallback, useContext} from 'react'
import s from './login.module.css'
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Context} from "../../Context/Context";
import {Box} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import logo from "../../assets/global-network.png";


export default function Login() {
    const {app, firestore, firebase, setNotification} = useContext(Context)


    const handleSignUp = useCallback(async event => {
        const {email, password} = event.target.elements;
        event.preventDefault();
        try {
            await app
                .auth()
                .createUserWithEmailAndPassword(email.value, password.value).then(cred => {

                    firestore.collection('users').doc(cred.user.uid).set({
                        user: cred.user.email,
                        devices: [],
                        log: [],
                        repairLog: [],
                        count: 10,
                        subType: 'Free',
                        role: 'user',
                        _id: cred.user.uid,
                    }).then(() => {
                        const user = firebase.auth().currentUser;
                        const emailVerified = user.emailVerified;
                        if (emailVerified === false) {
                            firebase.auth().onAuthStateChanged(function (user) {
                                user.sendEmailVerification();
                                setNotification(<p>We have sent a confirmation email to : <b>{email.value}</b>! <br />
                                    To continue working, confirm your email</p>)
                            })
                        }
                    })

                });

        } catch (error) {
            setNotification(`${error}`);
        }
    }, [app, firestore,firebase,setNotification])



    return (
        <Container>
            <Grid container style={{height: window.innerHeight - 64, marginTop: '64px'}}
                  alignItems={'center'}
                  justify={'center'}
                  direction={'column'}
            >
                <div className={s.logo}>
                    <img src={logo} alt=""/>
                    <p >Uvsoft</p>
                </div>

                <Grid className={s.login}
                      container
                      alignItems={'center'}
                      direction={'column'}>
                    <div className={s.title} >
                        Sign <span>up</span> to continue to: <br/>
                        <p >Uvsoft</p>
                    </div>
                    <form className={s.form} onSubmit={handleSignUp}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            size='small'
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            size='small'
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button className={s.btn} type='submit'>Sign Up</Button>
                        <Box  style={{textAlign: 'center', padding: '20px 0'}}>
                            <NavLink to={'/login'} >Do you already have account?</NavLink>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}
