import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import s from './policy.module.css'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useHistory, useLocation} from "react-router-dom";

const Policy = () => {

    const history = useHistory()
    const { pathname } = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container className={s.container}>
            <h3>Website Privacy Policy </h3>
            <p>This privacy policy sets out how UVSoft uses and protects any information that you give UVSoft when you
                use this website.</p>
            <p>UVSoft is committed to ensuring that your privacy is protected. Should we ask you to provide certain
                information by which you can be identified when using this website, then you can be assured that it will
                only be used in accordance with this privacy statement.</p>
            <p>UVSoft may change this policy from time to time by updating this page. You should check this page from
                time to time to ensure that you are happy with any changes. This policy is effective from 24.08.2021</p>
            <h5>What we collect</h5>
            <p>We may collect the following information:</p>
            <ul>
                <li>name and job title</li>
                <li>contact information including email address</li>
                <li>demographic information such as postcode, preferences and interests</li>
                <li>other information relevant to customer surveys and/or offers</li>
            </ul>
            <h5>What we do with the information we gather</h5>
            <p>We require this information to understand your needs and provide you with a better service, and in
                particular for the following reasons:</p>

            <ul>
                <li>Internal record keeping.</li>
                <li>We may use the information to improve our products and services</li>
                <li>We may periodically send promotional emails about new products, special offers or other information
                    which we think you may find interesting using the email address which you have provided.
                </li>
                <li>From time to time, we may also use your information to contact you for market research purposes. We
                    may contact you by email, phone, fax or mail. We may use the information to customise the website
                    according to your interests.
                </li>
            </ul>

            <h5>Security</h5>

            <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or
                disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and
                secure the information we collect online.</p>

            <h5>How we use cookies</h5>

            <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you
                agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a
                particular site. Cookies allow web applications to respond to you as an individual. The web application
                can tailor its operations to your needs, likes and dislikes by gathering and remembering information
                about your preferences.
            </p>
            <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about
                webpage traffic and improve our website in order to tailor it to customer needs. We only use this
                information for statistical analysis purposes and then the data is removed from the system.</p>
            <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you
                find useful and which you do not. A cookie in no way gives us access to your computer or any information
                about you, other than the data you choose to share with us.</p>
            <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can
                usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking
                full advantage of the website.</p>
            <h5>Links to other websites</h5>
            <p>Our website may contain links to other websites of interest. However, once you have used these links to
                leave our site, you should note that we do not have any control over that other website. Therefore, we
                cannot be responsible for the protection and privacy of any information which you provide whilst
                visiting such sites and such sites are not governed by this privacy statement. You should exercise
                caution and look at the privacy statement applicable to the website in question.</p>
            <h5>Controlling your personal information</h5>
            <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>

            <ul>
                <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to
                    indicate that you do not want the information to be used by anybody for direct marketing purposes
                </li>
                <li>If you have previously agreed to us using your personal information for direct marketing purposes,
                    you may change your mind at any time by writing to or emailing us at info@uvsoft.co
                </li>
            </ul>

            <p>We will not sell, distribute or lease your personal information to third parties unless we have your
                permission or are required by law to do so. We may use your personal information to send you promotional
                information about third parties which we think you may find interesting if you tell us that you wish
                this to happen.</p>

            <Box id={'contact'} className={s.contact}>
                <h4>Contacting Us</h4>

                <p>If there are any questions regarding this privacy policy you may contact us using the information below:
                </p>
                <p>Company Name - UVSoft</p>
                <p>Address- 283-6a, Central Town, Jalandhar, Punjab. 144001</p>
                <p >Telephone Number - <a href="tel:+918699603469">+91 8699 603469</a></p>
                <p>E-Mail ID -
                <a aria-label="Email to us"
                   href="mailto:info@uvsoft.co">info@uvsoft.co</a></p>
            </Box>
            <Button onClick={() => history.push('/')} variant={'contained'} color={'primary'} > Back to main</Button>
        </Container>
    );
};

export default Policy;