import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import MaterialTable from 'material-table'
import InStock from "./InStock";
import s from './console.module.css'
import dwnld from '../../assets/cloud-computing.png'
import ShowDetails from "./ShowDetails";
import Button from "@material-ui/core/Button";


const RepairLog = ({data, value, download, changeRepairStatus, ...props}) => {
    const [device, setDevice] = useState(null)
    const [show, setShow] = useState(0)
    const [details, setDetails] = useState(false)


    const toggleTypeMenu = (index) => {
        const submenu = document.querySelectorAll('#submenu')[index]
        const arrow = document.querySelectorAll('#arrow')[index]

        setTimeout(() => {
            if (details) {
                submenu.style.display = 'none';
                arrow.innerHTML = 'expand_more'
            } else {
                submenu.style.display = 'block';
                arrow.innerHTML = 'expand_less'
            }
        }, 200)
        setDetails(!details)
    }
    const changeStatus = (type, status, index, id) => {

        toggleTypeMenu(index)
        if (type === status) return type;

        changeRepairStatus(type, id)
    }

    const status = (value) => {

        switch (value) {
            case 'Fixed' :
                return <p className={s.repairStatus} style={{backgroundColor: 'rgb(138, 198, 136)'}}> Fixed </p>
            case 'Not fixed' :
                return <p className={s.repairStatus} style={{backgroundColor: 'rgb(231,86,86)'}}>Not fixed </p>
            default:
                return <p className={s.repairStatus} style={{backgroundColor: 'rgb(107, 183, 245)'}}> In progress</p>
        }
    }


    const columns = [
        {
            field: 'IMEI/SN',
            title: 'IMEI / Serial number',
            cellStyle: {padding: '5px 0 5px 10px', fontSize: '15px', cursor: 'pointer'},
        },
        {field: 'model', title: 'Model', cellStyle: {padding: '5px', cursor: 'pointer'}},
        {field: 'person', title: 'Customer Name', cellStyle: {padding: '5px', cursor: 'pointer'}},
        {field: 'phoneNumber', title: 'Phone', cellStyle: {padding: '5px', cursor: 'pointer'}},
        {
            field: 'image', title: 'Proof', cellStyle: {padding: '5px', cursor: 'pointer'},
            render: (rowData) => (rowData.image && rowData.image?.length > 0 ?
                    <div
                        className={s.dwnld}
                        onClick={() => download(rowData.image)}
                    ><img style={{height: '20px', marginLeft: '10px', cursor: 'pointer'}} alt={'download'} src={dwnld}/>
                    </div> : null
            ),
        },
        {
            field: 'status', title: 'Status', cellStyle: {padding: '5px', cursor: 'pointer'},
            render: (rowData) => (
                <div style={{color: 'white'}}>
                    {status(rowData.status)}
                </div>),
        },
        {
            field: 'actions', title: 'Actions', cellStyle: {padding: '5px'},
            render: (rowData) => (
                <div style={{position: 'relative'}}>
                    <Button variant={'outlined'} color={'primary'} onClick={() => toggleTypeMenu(rowData.index)}
                            style={{padding: '0 10px'}}>
                        Type
                        <span id={'arrow'} style={{fontSize: '19px', marginLeft: '5px'}}
                              className="material-icons">expand_more</span>
                    </Button>
                    <ul id={'submenu'} className={s.submenu}>
                        <li><p onClick={() => changeStatus('Not fixed', rowData.status, rowData.index, rowData.id)}>Not fixed</p>
                        </li>
                        <li><p onClick={() => changeStatus('Fixed', rowData.status, rowData.index, rowData.id)}>Fixed</p></li>
                        <li><p onClick={() => changeStatus('In progress', rowData.status, rowData.index, rowData.id)}>In
                            progress</p></li>
                    </ul>
                </div>),
        },

    ];

    const sorting = (data) => {
        let sortable = []

        for (let i = 0; i < data.length; i++) {
            if(data[i].status === 'In progress') {
                sortable.unshift(data[i])
            } else {
                sortable.push(data[i])
            }
            // ещё какие-то выражения
        }
        return sortable
    }

    const rows = sorting(data).map((d, index) => {

        return {
            'IMEI/SN': d.IMEI ? d.IMEI : d.serialNumber,
            model: d.model,
            person: d.person,
            phoneNumber: d.phoneNumber,
            image: d.image,
            date: d.date,
            status: d.status,
            index: index,
            id: d.id,
        }
    })


    return (
        <>
            <Box
                container='true'
                item='true' xs={12}
                direction="row"
                className={s.log}>
                <MaterialTable
                    remoteData={data.length}
                    onRowClick={((evt, selectedRow) => {

                        if (evt.target.tagName !== 'BUTTON' && evt.target.tagName !== 'P'
                            && evt.target.tagName !== 'SPAN'
                            && evt.target.tagName !== 'IMG'
                            && evt.target.tagName !== 'LI') {
                            setDevice(sorting(data)[selectedRow.tableData.id]);
                            setShow(1)
                        }
                    })}
                    components={{
                        Toolbar: props => (
                            <div style={{display: 'none'}}/>
                        ),
                    }}
                    options={{
                        search: false,
                        showTitle: false,
                        sorting: true,
                        pageSize: 7,
                        pageSizeOptions: [7, 15, 20, 50],
                        headerStyle: {
                            padding: '10px',
                        }
                    }}
                    data={[...rows]}
                    columns={columns} pageSize={5}/>

            </Box>
            <Box className={s.mobileLog}>
                <InStock download={download} changeStatus={changeStatus} data={sorting(data)} page={'repair'} value={2}/>
            </Box>
            {show ? <ShowDetails device={device}
                                 download={download}
                                 show={show}
                                 setShow={setShow}/> : null}
        </>
    );
};

export default RepairLog;